import axios from 'axios';
import NProgress from 'nprogress'
import { toast } from 'react-toastify'


export const findQuestion = (arr, id) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].fields.question_id == id) {
      return { question: { ...arr[i] }, currentQuestionIndex: i }
    }
  }
}




export const generateReport = (id, reqBody) => {
    return new Promise((resolve, reject) => {
      NProgress.start()
      axios.post(`${process.env.API_URL}public/surveys/${id}/download`, reqBody, {
        headers: {
          'Content-type': 'application/json',
        },
        responseType: 'blob'
      }).then((res) => {
        NProgress.done()
        var binaryData = [];
        binaryData.push(res.data);
        var fileURL = URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }))
        window.open(fileURL, "_self");
        resolve(1)
      }).catch((e) => {
        toast.error('Please check your internet connection and submit the assessment again')
        NProgress.done()
        reject(e)
      })
    })
  }

  
  
export const emptyAnswerArray = (obj, previousAnswers) => {
  try {
    let previousAnswersLength = previousAnswers.questions.length
    for (let i = 0; i < previousAnswersLength; i++) {
      if (obj[Number(previousAnswers.questions[i].id)]) {
        // previousAnswers.questions[i].answers = []
        previousAnswers.questions.splice(i, 1)
        --i
        --previousAnswersLength
      }
    }
  }
  catch (e) {
    
  }
}

export const updateAnswers = (arr, index, object, update) => {
  // const index = arr.questions.findIndex(item => item.id == id) 
  // debugger;
  if (update) {
    arr.questions[index - 1] = object
  }
  else {
    arr.questions.splice(index - 1, 0, object)
  }
}

function getQuestion3CheckedOptions(previousAnswers){
    const optionsChecked = [
        {
            isChecked: false,
            skipToId: '105'
        },
        {
            isChecked: false,
            skipToId: '145'
        },
        {
            isChecked: false,
            skipToId: '146'
        },
        {
            isChecked: false,
            skipToId: '147'
        },
        {
            isChecked: false,
            skipToId: '148'
        },
        {
            isChecked: false,
            skipToId: '149'
        },
        {
            isChecked: false,
            skipToId: '150'
        },
        {
            isChecked: false,
            skipToId: '151'
        },
        {
            isChecked: false,
            skipToId: '152'
        },
        {
            isChecked: false,
            skipToId: '153'
        },
        {
            isChecked: false,
            skipToId: '154'
        },
        {
            isChecked: false,
            skipToId: '155'
        },
        {
            isChecked: false,
            skipToId: '156'
        },
        {
            isChecked: true,
            skipToId: '117'
        },
        {
            isChecked: true,
            skipToId: '117'
        }
    ]
    const ageAnswer = previousAnswers.questions.find(item => item.id == 7)

    const questionNo90Answer = previousAnswers.questions.find(item => item.id == 90)
    if(ageAnswer && ageAnswer.answers && questionNo90Answer && questionNo90Answer.answers){
        const age = ageAnswer.answers[0].text
        questionNo90Answer.answers.forEach((item => {
            if(item.other_id == 568 && age  - item.text  <  2){
                optionsChecked[0].isChecked = true
            }
            else if(item.other_id == 570 && age  - item.text  <  2){
                optionsChecked[1].isChecked = true
            }
            else if(item.other_id == 572 && age  - item.text  <  2){
                optionsChecked[2].isChecked = true
            }
            else if(item.other_id == 574 && age  - item.text  <  2){
                optionsChecked[3].isChecked = true
            }
            else if(item.other_id == 576 && age  - item.text  <  2){
                optionsChecked[4].isChecked = true
            }
            else if(item.other_id == 578 && age  - item.text  <  2){
                optionsChecked[5].isChecked = true
            }
            else if(item.other_id == 580 && age  - item.text  <  2){
                optionsChecked[6].isChecked = true
            }
            else if(item.other_id == 582 && age  - item.text  <  2){
                optionsChecked[7].isChecked = true
            }
            else if(item.other_id == 584 && age  - item.text  <  2){
                optionsChecked[8].isChecked = true
            }
            else if(item.other_id == 586 && age  - item.text  <  2){
                optionsChecked[9].isChecked = true
            }
            else if(item.other_id == 588 && age  - item.text  <  2){
                optionsChecked[10].isChecked = true
            }
            
            else if(item.other_id == 590 && age  - item.text  <  2){
                optionsChecked[11].isChecked = true
            }
            else if(item.other_id == 592 && age  - item.text  <  2){
                optionsChecked[12].isChecked = true
            }
        }))
    }
    return optionsChecked
}

const aboutTheSameQuestions = [106, 110, 114, 157, 205, 158, 206, 159, 207, 160, 208, 162, 210, 163, 211, 164, 212, 165, 213, 166, 214, 167, 215, 168, 161,209,216]

const aboutTheSameQuestionsSkip = {
    106: {
        slice: 1,
        elseSkip: 107,
        skipOptionId: 837,
        checkPreviousSymptoms: 105,
        skipToId: 109
    },
    110: {
        slice: 1,
        elseSkip: 111,
        skipOptionId: 1266,
        skipToId: 113
    },
    114: {
        slice: 1,
        elseSkip: 115,
        skipOptionId: 1453
    },
    157: {
        slice: 2,
        elseSkip: 169,
        skipOptionId: 841,
        skipToId: 193,
        checkPreviousSymptoms: 145,
    },
    205: {
        slice: 2,
        elseSkip: 217,
        skipOptionId: 1270
    },
    158: {
        slice: 3,
        elseSkip: 170,
        skipOptionId: 845,
        skipToId: 194,
        checkPreviousSymptoms: 146,
    },
    206: {
        slice: 3,
        elseSkip: 218,
        skipOptionId: 1274
    },
    159: {
        slice: 4,
        elseSkip: 171,
        skipOptionId: 849,
        skipToId: 195,
        checkPreviousSymptoms: 147,
    },
    207: {
        slice: 4,
        elseSkip: 219,
        skipOptionId: 1278
    },
    160: {
        slice: 5,
        elseSkip: 172,
        skipOptionId: 853,
        skipToId: 196,
        checkPreviousSymptoms: 148,
    },
    208: {
        slice: 5,
        elseSkip: 220,
        skipOptionId: 1282
    },
    161: {
        slice: 6,
        elseSkip: 173,
        skipOptionId: 857,
        skipToId: 197,
        checkPreviousSymptoms: 149,
    },
    209: {
        slice: 6,
        elseSkip: 221,
        skipOptionId: 1286
    },
    162: {
        slice: 7,
        elseSkip: 174,
        skipOptionId: 861,
        skipToId: 198,
        checkPreviousSymptoms: 150,
    },
    210: {
        slice: 7,
        elseSkip: 222,
        skipOptionId: 1290
    },
    163: {
        slice: 8,
        elseSkip: 175,
        skipOptionId: 865,
        skipToId: 199,
        checkPreviousSymptoms: 151,
    },
    211: {
        slice: 8,
        elseSkip: 223,
        skipOptionId: 1294
    },
    164: {
        slice: 9,
        elseSkip: 176,
        skipOptionId: 869,
        skipToId: 200,
        checkPreviousSymptoms: 152,
    },
    212: {
        slice: 9,
        elseSkip: 224,
        skipOptionId: 1298
    },
    165: {
        slice: 10,
        elseSkip: 177,
        skipOptionId: 873,
        skipToId: 201,
        checkPreviousSymptoms: 153,
    },
    213: {
        slice: 10,
        elseSkip: 225,
        skipOptionId: 1302
    },
    166: {
        slice: 11,
        elseSkip: 178,
        skipOptionId: 877,
        skipToId: 202,
        checkPreviousSymptoms: 154,
    },
    214: {
        slice: 11,
        elseSkip: 226,
        skipOptionId: 1306
    },
    167: {
        slice: 12,
        elseSkip: 179,
        skipOptionId: 881,
        skipToId: 203,
        checkPreviousSymptoms: 155,
    },
    215: {
        slice: 12,
        elseSkip: 227,
        skipOptionId: 1310
    },
    168: {
        slice: 13,
        elseSkip: 180,
        skipOptionId: 885,
        skipToId: 204,
        checkPreviousSymptoms: 156,
    },
    216: {
        slice: 13,
        elseSkip: 228,
        skipOptionId: 1314
    },
}

const symptomsQuantityCheckQuestions = [108,181,182,183,184,185,186,187,188,189,190,191]

const symptomsQuantityCheckQuestionsSkip = {
    108: {
        symptomsQuantityCheckQuestion: 105,
        symptomsQuantityCheckQuestion2 : 107,
        symptomsQuantityCheckQuestion3 : 108,
        symptomsCount: 2,
        slice: 1
    },
    181: {
        symptomsQuantityCheckQuestion: 145,
        symptomsQuantityCheckQuestion2 : 169,
        symptomsQuantityCheckQuestion3 : 181,
        symptomsCount: 2,
        slice: 2
    },
    182: {
        symptomsQuantityCheckQuestion: 146,
        symptomsQuantityCheckQuestion2 : 170,
        symptomsQuantityCheckQuestion3 : 182,
        symptomsCount: 2,
        slice: 3
    },
    183: {
        symptomsQuantityCheckQuestion: 147,
        symptomsQuantityCheckQuestion2 : 171,
        symptomsQuantityCheckQuestion3 : 183,
        symptomsCount: 2,
        slice: 4
    },
    184: {
        symptomsQuantityCheckQuestion: 148,
        symptomsQuantityCheckQuestion2 : 172,
        symptomsQuantityCheckQuestion3 : 184,
        symptomsCount: 2,
        slice: 5
    },
    185: {
        symptomsQuantityCheckQuestion: 149,
        symptomsQuantityCheckQuestion2 : 173,
        symptomsQuantityCheckQuestion3 : 185,
        symptomsCount: 2,
        slice: 6
    },
    186: {
        symptomsQuantityCheckQuestion: 150,
        symptomsQuantityCheckQuestion2 : 174,
        symptomsQuantityCheckQuestion3 : 186,
        symptomsCount: 2,
        slice: 7
    },
    187: {
        symptomsQuantityCheckQuestion: 151,
        symptomsQuantityCheckQuestion2 : 175,
        symptomsQuantityCheckQuestion3 : 187,
        symptomsCount: 2,
        slice: 8
    },
    188: {
        symptomsQuantityCheckQuestion: 152,
        symptomsQuantityCheckQuestion2 : 176,
        symptomsQuantityCheckQuestion3 : 188,
        symptomsCount: 2,
        slice: 9
    },
    189: {
        symptomsQuantityCheckQuestion: 153,
        symptomsQuantityCheckQuestion2 : 177,
        symptomsQuantityCheckQuestion3 : 189,
        symptomsCount: 2,
        slice: 10
    },
    190: {
        symptomsQuantityCheckQuestion: 154,
        symptomsQuantityCheckQuestion2 : 178,
        symptomsQuantityCheckQuestion3 : 190,
        symptomsCount: 2,
        slice: 11
    },
    191: {
        symptomsQuantityCheckQuestion: 155,
        symptomsQuantityCheckQuestion2 : 179,
        symptomsQuantityCheckQuestion3 : 191,
        symptomsCount: 2,
        slice: 12
    },
    192: {
        symptomsQuantityCheckQuestion: 156,
        symptomsQuantityCheckQuestion2 : 180,
        symptomsQuantityCheckQuestion3 : 192,
        symptomsCount: 2,
        slice: 13
    }
}

function opioidQuestions(previousAnswers, history){
    const questionNo90Answer = previousAnswers.questions.find(item => item.id == 90)
    const lastUsed = {
        opiates : 0,
        heroin: 0
    }
    const ageAnswer = previousAnswers.questions.find(item => item.id == 7)
    const age = ageAnswer.answers[0].text
    questionNo90Answer.answers.forEach(({choice_id, text}) => {
        if(choice_id == 570){
            lastUsed.opiates = text
        }
        if(choice_id == 582){
            lastUsed.heroin = text
        }
    })
    if(Number(lastUsed.opiates) < (Number(age) - 1) && Number(lastUsed.heroin) < (Number(age) - 1)) {
        const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
        if(!(questionNo4Answer && questionNo4Answer.answers)){
                deletePreviousAnswers(questionIndex, 144, previousAnswers)
                history.push(`/questions/144`)
                return;
        }
        else{
            history.push(`/questions/130`)
        }
    } else{
        history.push(`/questions/127`)
    }
}


const deletePreviousAnswers = (indexToStart, idToDeleteTill, previousAnswers) => {
    const answersToDelete = {}
    for(let index = indexToStart + 1; ; index++){
        if(questionsAndChoices[index].fields.question_id == idToDeleteTill){
            break;
        }
        answersToDelete[questionsAndChoices[index].fields.question_id] = true
    }
    emptyAnswerArray(answersToDelete, previousAnswers)
}

export const nextRoute = (previousAnswers, currentQuestionId, history, currentAnswer, questionIndex) => {
  if(currentQuestionId == 2){
    if(currentAnswer.every(({choice_id}) => choice_id == 4 || choice_id == 6 || choice_id == 7)){
        emptyAnswerArray({3: true, 4: true, 5: true}, previousAnswers)
        history.push(`/questions/6`)
        return;
    }
    let isSubstanceChecked, isAHabitChecked, isUnwantedThoughtsChecked
    currentAnswer.forEach(({choice_id}) => {
        if(choice_id == 1){
            isSubstanceChecked = true
        }
        if(choice_id == 2){
            isAHabitChecked = true
        }
        if(choice_id == 3){
            isUnwantedThoughtsChecked = true
        }
    })
    if(!isSubstanceChecked){
        if(!isAHabitChecked){
            if(!isUnwantedThoughtsChecked){
                emptyAnswerArray({3: true, 4: true, 5: true}, previousAnswers)
                history.push(`/questions/6`)
                return;
            }
            emptyAnswerArray({3: true, 4: true}, previousAnswers)
            history.push(`/questions/5`)
            return;
        }
        emptyAnswerArray({3: true}, previousAnswers)
        history.push(`/questions/4`)
        return;
    }
    history.push(`/questions/3`)
  }
  else if(currentQuestionId == 3){
    const questionNo2Answer = previousAnswers.questions.find(item => item.id == 2).answers
    if(questionNo2Answer.every(({choice_id}) => choice_id != 2)){
        if(questionNo2Answer.every(({choice_id}) => choice_id != 3)){
            emptyAnswerArray({4: true, 5: true, 6:true}, previousAnswers)
            history.push(`/questions/6`)
        }
        else{
            emptyAnswerArray({4: true}, previousAnswers)
            history.push(`/questions/5`)
        }
    }
    else{
        history.push(`/questions/4`)
    }
  }
  
  else if(currentQuestionId == 4){
    const questionNo2Answer = previousAnswers.questions.find(item => item.id == 2)
    if(questionNo2Answer && questionNo2Answer.answers && questionNo2Answer.answers.every(({choice_id}) => choice_id != 3)){
        emptyAnswerArray({ 5: true}, previousAnswers)
        history.push(`/questions/6`)
    }

    else{
        history.push(`/questions/5`)
    }
  }
//   else if(currentQuestionId == 5){
//     const questionNo2Answer = previousAnswers.questions.find(item => item.id == 2).answers
//     if(questionNo2Answer.every(({choice_id}) => choice_id != 3)){
//         emptyAnswerArray({ 6: true}, previousAnswers)
//         history.push(`/questions/7`)
//     }
//     else{
//         history.push(`/questions/6`)
//     }
//   }
  else if(currentQuestionId == 13){
    if(currentAnswer.some(({choice_id}) => choice_id == 94 || choice_id == 95)){
        emptyAnswerArray({ 14: true},{15:true}, previousAnswers)
        history.push(`/questions/16`)
        return;
    }
    else{
        history.push(`/questions/14`)
    }
  }
  else if(currentQuestionId == 14){
    const questionNo13Answer = previousAnswers.questions.find(item => item.id == 13).answers
    if(questionNo13Answer.some(({choice_id}) => choice_id == 96 || choice_id == 97)){
        emptyAnswerArray({ 15: true}, previousAnswers)
        history.push(`/questions/16`)
    }
    else{
        history.push(`/questions/15`)
    }
  }
  else if(currentQuestionId == 18){
    const questionNo13Answer = previousAnswers.questions.find(item => item.id == 13).answers
    if(questionNo13Answer.some(({choice_id}) => choice_id == 105)){
        emptyAnswerArray({ 19: true, 20: true}, previousAnswers)
        history.push(`/questions/21`)
    }
    else if(questionNo13Answer.some(({choice_id}) => choice_id == 96 || choice_id == 97)){
        emptyAnswerArray({ 19: true}, previousAnswers)
        history.push(`/questions/20`)
    }
    else{
        history.push(`/questions/19`)
    }
  }
  else if(currentQuestionId == 69){
    if(currentAnswer.some(({choice_id}) => choice_id == 432)){
        emptyAnswerArray({ 70: true}, previousAnswers)
        history.push(`/questions/71`)
    }
    else{
        history.push(`/questions/70`)
    }
  }
  else if(currentQuestionId == 21){
    const questionNo13Answer = previousAnswers.questions.find(item => item.id == 13).answers
    if(questionNo13Answer.some(({choice_id}) => choice_id == 96 || choice_id == 97 || choice_id == 105)){
        emptyAnswerArray({ 22: true}, previousAnswers)
        history.push(`/questions/23`)
    }
    else{
        history.push(`/questions/22`)
    }
  }
  else if(currentQuestionId == 20){
    if(currentAnswer.every(({choice_id}) => choice_id != 133)){
        const questionNo13Answer = previousAnswers.questions.find(item => item.id == 13).answers
        if(questionNo13Answer.some(({choice_id}) => choice_id == 96 || choice_id == 97 || choice_id == 105)){
            emptyAnswerArray({ 21: true,  22: true}, previousAnswers)
            history.push(`/questions/23`)
        }
        else{
            emptyAnswerArray({ 21: true }, previousAnswers)
            history.push(`/questions/22`)
        }
    }
    else{
        history.push(`/questions/21`)
    }
  }
  else if(currentQuestionId == 24){
    if(currentAnswer.every(({choice_id}) => choice_id != 155)){
        emptyAnswerArray({ 25: true,  26: true,  27: true }, previousAnswers)
        history.push(`/questions/28`)
        return;
    }
    else{
        history.push(`/questions/25`)
    }
  }
  else if(currentQuestionId == 59){
    const questionNo5Answer = previousAnswers.questions.find(item => item.id == 5)
    if(questionNo5Answer && questionNo5Answer.answers){
        history.push(`/questions/60`)
    }
    else{
        let myDict = {};
        for (let i = 60; i <= 142; i++) {
            myDict[i] = true;
        }
        emptyAnswerArray(myDict, previousAnswers)

        history.push(`/questions/143`)
    }
  }
  else if(currentQuestionId == 70){
    if(currentAnswer.some(({choice_id}) => choice_id != 432)){
        deletePreviousAnswers(questionIndex, 72, previousAnswers)
        history.push(`/questions/72`)
        return;
    }
    else{
        history.push(`/questions/71`)
    }
  }
  else if(currentQuestionId == 60){
    if(currentAnswer.some(({choice_id}) => choice_id != 344)){
        emptyAnswerArray({ 61: true}, previousAnswers)
        history.push(`/questions/62`)
        return;
    }
    else{
        history.push(`/questions/61`)
    }
  }
  else if(currentQuestionId == 62){
    if(currentAnswer.some(({choice_id}) => choice_id == 362)){
        emptyAnswerArray({ 63: true}, previousAnswers)
        history.push(`/questions/64`)
        return;
    }
    else{
        history.push(`/questions/63`)
    }
  }
  else if(currentQuestionId == 89){
    if(currentAnswer.some(({choice_id}) => choice_id == 560)){
        let myDict = {};
        for (let i = 90; i <= 143; i++) {
            myDict[i] = true;
        }
        emptyAnswerArray(myDict, previousAnswers)
        deletePreviousAnswers(questionIndex, 144, previousAnswers)
        history.push(`/questions/144`)
        return;
    }
    else{
        history.push(`/questions/90`)
    }
  }
  else if(currentQuestionId == 91){
    if(currentAnswer.some(({choice_id}) => choice_id == 610)){
            const questionNo89Answer = previousAnswers.questions.find(item => item.id == 89).answers
            const tobaccoChoiceIds = [543, 544, 545]
            if(!questionNo89Answer.some(({choice_id}) => tobaccoChoiceIds.some(id => id == choice_id))){
                const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
                
                for(const option of question3OptionsChecked){
                    if(option.isChecked){
                        let myDict = {};
                        // for (let i = 93; i <= option.skipToId - 1 ; i++) {
                        //     myDict[i] = true;
                        // }
                        
                        // emptyAnswerArray(myDict, previousAnswers)
                        deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                        history.push(`/questions/${option.skipToId}`)
                        return;
                    }
                }
            }
            else{
                history.push(`/questions/93`)
            }
    }
    else{
        history.push(`/questions/92`)
    }
  }
  else if(currentQuestionId == 92){
    const questionNo89Answer = previousAnswers.questions.find(item => item.id == 89) ? previousAnswers.questions.find(item => item.id == 89).answers :[]
    const questionNo91Answer = previousAnswers.questions.find(item => item.id == 91) ? previousAnswers.questions.find(item => item.id == 91).answers:[]
    const tobaccoChoiceIds = [543, 544, 545]
    if(!questionNo89Answer.some(({choice_id}) => tobaccoChoiceIds.some(id => id == choice_id))){
        if(questionNo91Answer.some(({choice_id}) => choice_id == 610)){
            let myDict = {};
            for (let i = 93; i <= 108; i++) {
                myDict[i] = true;
            }
            emptyAnswerArray(myDict, previousAnswers)

            history.push(`/questions/109`)
            return;
        }
        else{
            const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
            
            for(const option of question3OptionsChecked){
                if(option.isChecked){
                    deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                    history.push(`/questions/${option.skipToId}`)
                    return;
                }
            }
        }
    }
    const questionNo3Answer = previousAnswers.questions.find(item => item.id == 3) ? previousAnswers.questions.find(item => item.id == 3).answers : []
    const question3TobaccoChoiceIds = [8, 9, 10]
    if(!questionNo3Answer.some(({choice_id}) => question3TobaccoChoiceIds.some(id => id == choice_id))){
        if(questionNo91Answer.some(({choice_id}) => choice_id == 610)){
            deletePreviousAnswers(questionIndex, 109, previousAnswers)
            history.push(`/questions/109`)
            return;
        }
        else{
            const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
            for(const option of question3OptionsChecked){
                if(option.isChecked){
                    deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                    history.push(`/questions/${option.skipToId}`)
                    return;
                }
            }
        }
    }
    const questionNo90Answer = previousAnswers.questions.find(item => item.id == 90) ? previousAnswers.questions.find(item => item.id == 90).answers :[]
    const ageAnswer = previousAnswers.questions.find(item => item.id == 7)
    const age = ageAnswer.answers[0].text
    const lastUsed = {
        smoking: 0,
        smokeless_tobacco: 0,
        vaping: 0
    }
    questionNo90Answer.forEach(({other_id, text}) => {
        if(other_id == 562){
            lastUsed.smoking = text
        }
        if(other_id == 564){
            lastUsed.smokeless_tobacco = text
        }
        if(other_id == 566){
            lastUsed.vaping = text
        }
    })

    if ((lastUsed.smoking < (age - 1)) && (lastUsed.smokeless_tobacco < (age - 1)) && (lastUsed.vaping < (age - 1))) {
        // history.push(`/questions/105`)
        const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
        for(const option of question3OptionsChecked){
            if(option.isChecked){
                deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                history.push(`/questions/${option.skipToId}`)
                return;
            }
        }
    }
    else{
        history.push(`/questions/93`)
    }
  }
  else if(currentQuestionId == 94){
    if(currentAnswer.some(({choice_id}) => choice_id == 632)){
        const questionNo93Answer = previousAnswers.questions.find(item => item.id == 93).answers
        if(questionNo93Answer.some(({choice_id}) => choice_id == 627 || choice_id == 628 || choice_id == 629)){
            const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
            for(const option of question3OptionsChecked){
                if(option.isChecked){
                    deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                    history.push(`/questions/${option.skipToId}`)
                    return;
                }
            }
        }
        else{
            emptyAnswerArray({ 95: true, 96: true}, previousAnswers)
            history.push(`/questions/97`)
        }
        return;
    }
    else{
        history.push(`/questions/95`)
    }
  }
  else if(currentQuestionId == 98){
    if(currentAnswer.some(({choice_id}) => choice_id == 651)){
        emptyAnswerArray({ 99: true, 100: true}, previousAnswers)
        history.push(`/questions/101`)
        return;
    }
    else{
        history.push(`/questions/99`)
    }
  }
else if(currentQuestionId == 102){
    if(currentAnswer.some(({choice_id}) => choice_id == 669)){
        const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
        for(const option of question3OptionsChecked){
            if(option.isChecked){
                deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                history.push(`/questions/${option.skipToId}`)
                return;
            }
        }
    }
    else{
        history.push(`/questions/103`)
    }
}
    else if(currentQuestionId == 104){
        const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
        for(const option of question3OptionsChecked){
            if(option.isChecked){
                deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                history.push(`/questions/${option.skipToId}`)
                return;
            }
        }
    }
    // else if(currentQuestionId == 106){
    //     const questionNo105Answer = previousAnswers.questions.find(item => item.id == 105)
    //     if(questionNo105Answer && questionNo105Answer.answers){
    //         if(questionNo105Answer.answers.length < 2 && currentAnswer.some(({choice_id}) => choice_id == 838)){
    //             history.push(`/questions/109`)
    //         } else{
    //             history.push(`/questions/107`)
    //        }
    //     }
    // }
  else if(currentQuestionId == 112){
    const questionNo90Answer = previousAnswers.questions.find(item => item.id == 90)
    const ageAnswer = previousAnswers.questions.find(item => item.id == 7)
    const age = ageAnswer.answers[0].text
    const lastUsed = {
        alcohol: ''
    }
    if(questionNo90Answer && questionNo90Answer.answers){
        questionNo90Answer.answers.forEach(({choice_id, text}) => {
            if(choice_id == 568){
                lastUsed.alcohol = text
                return;
            }
        })
        if(lastUsed.alcohol < age - 1){
            const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
            const sliced = question3OptionsChecked.slice(1)
            for(const option of sliced){
                if(option.isChecked){
                    deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                    history.push(`/questions/${option.skipToId}`)
                    return;
                }
            }
        }
        else{
            history.push(`/questions/113`)
        }
    }
    else{
        history.push(`/questions/113`)
    }
  }
  else if(currentQuestionId == 116){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(1)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 229){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(2)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 230){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(3)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 231){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(4)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 232){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(5)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 233){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(6)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 234){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(7)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 235){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(8)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 236){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(9)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 237){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(10)
    for(const option of sliced){
        if(option.isChecked){
            let myDict = {};
            for (let i = 238; i <= option.skipToId - 1 ; i++) {
                myDict[i] = true;
            }
            
            emptyAnswerArray(myDict, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 238){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(11)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 239){
    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    const sliced = question3OptionsChecked.slice(12)
    for(const option of sliced){
        if(option.isChecked){
            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
            history.push(`/questions/${option.skipToId}`)
            return;
        }
    }
  }
  else if(currentQuestionId == 240){
    // const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
    // const sliced = question3OptionsChecked.slice(13)
    // sliced.forEach(option => {
    //     if(!option.isChecked){
    //         history.push(`/questions/${option.skipToId}`)
    //         return;
    //     }
    // })
    history.push(`/questions/117`)
  }
  else if(currentQuestionId == 117){
    if(currentAnswer.some(({choice_id}) => choice_id == 1485)){
        emptyAnswerArray({ 118: true, 119: true, 120: true, 121: true}, previousAnswers)

        history.push(`/questions/122`)
        return;
    }
    else{
        history.push(`/questions/118`)
    }
  }
  else if(currentQuestionId == 118){
    const questionNo117Answer = previousAnswers.questions.find(item => item.id == 117).answers ?  previousAnswers.questions.find(item => item.id == 117).answers : []
    if(questionNo117Answer.some(({choice_id}) => choice_id != 1478)){
        const questionNo91Answer = previousAnswers.questions.find(item => item.id == 91).answers ? previousAnswers.questions.find(item => item.id == 91).answers : []
        if(questionNo91Answer.some(({choice_id}) => choice_id == 610)){
            const opiatesPastMonth = previousAnswers.questions.find(item => item.id == 145)
            const heroinPastMonth = previousAnswers.questions.find(item => item.id == 151)
            let opiatesWithdrawlChecked = true
            let heroinWithdrawlChecked = true
            if(opiatesPastMonth && opiatesPastMonth.answers){
                if(opiatesPastMonth.answers.some(({choice_id}) => choice_id == 703)){
                    opiatesWithdrawlChecked = true
                }
                else{
                    opiatesWithdrawlChecked = false
                }
            }
            if(heroinPastMonth && heroinPastMonth.answers){
                if(heroinPastMonth.answers.some(({choice_id}) => choice_id == 775)){
                    heroinWithdrawlChecked = true
                }
                else{
                    heroinWithdrawlChecked = false
                }
            }
            if(opiatesWithdrawlChecked &&  heroinWithdrawlChecked){
                deletePreviousAnswers(questionIndex, 126, previousAnswers)
                history.push(`/questions/126`)    
            }
            else{
                const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
                if(!(questionNo4Answer && questionNo4Answer.answers)){
                    deletePreviousAnswers(questionIndex, 144, previousAnswers)
                    history.push(`/questions/144`)
                    return;
                }
                else{
                    if(questionNo4Answer && questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                        deletePreviousAnswers(questionIndex, 134, previousAnswers)
                        history.push(`/questions/134`)
                        return;
                    }
                    else{
                        deletePreviousAnswers(questionIndex, 130, previousAnswers)
                        history.push(`/questions/130`)
                        return;
                    }
                }
            }
        }
        else{
            emptyAnswerArray({ 119: true, 120: true, 121: true}, previousAnswers)
            history.push(`/questions/122`)
            return;
        }
    }
    else{
        history.push(`/questions/119`)
    }
  }
  else if(currentQuestionId == 119){
    if(currentAnswer.some(({choice_id}) => choice_id != 1503 && choice_id != 1507)){
        const questionNo91Answer = previousAnswers.questions.find(item => item.id == 91).answers
        if(questionNo91Answer.some(({choice_id}) => choice_id == 610)){
            const opiatesPastMonth = previousAnswers.questions.find(item => item.id == 145)
            const heroinPastMonth = previousAnswers.questions.find(item => item.id == 151)
            let opiatesWithdrawlChecked = true
            let heroinWithdrawlChecked = true
            if(opiatesPastMonth && opiatesPastMonth.answers){
                if(opiatesPastMonth.answers.some(({choice_id}) => choice_id == 703)){
                    opiatesWithdrawlChecked = true
                }
                else{
                    opiatesWithdrawlChecked = false
                }
            }
            if(heroinPastMonth && heroinPastMonth.answers){
                if(heroinPastMonth.answers.some(({choice_id}) => choice_id == 775)){
                    heroinWithdrawlChecked = true
                }
                else{
                    heroinWithdrawlChecked = false
                }
            }
            if(opiatesWithdrawlChecked &&  heroinWithdrawlChecked){
                deletePreviousAnswers(questionIndex, 126, previousAnswers)
                history.push(`/questions/126`)    
            }
            else{
                const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
                if(!(questionNo4Answer && questionNo4Answer.answers)){
                    deletePreviousAnswers(questionIndex, 144, previousAnswers)
                    history.push(`/questions/144`)
                    return;
                }
                else{
                    if(questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                        deletePreviousAnswers(questionIndex, 134, previousAnswers)
                        history.push(`/questions/134`)
                        return;
                    }
                    else{
                        deletePreviousAnswers(questionIndex, 130, previousAnswers)
                        history.push(`/questions/130`)
                        return;
                    }
                }
            }
        }
        else{
            emptyAnswerArray({ 120: true, 121: true}, previousAnswers)
            history.push(`/questions/122`)
            return;
        }
    }
    else{
        history.push(`/questions/120`)
    }
  }
  else if(currentQuestionId == 121){
    const questionNo91Answer = previousAnswers.questions.find(item => item.id == 91)
    if(questionNo91Answer && questionNo91Answer.answers && questionNo91Answer.answers.some(({choice_id}) => choice_id == 610)){
        const opiatesPastMonth = previousAnswers.questions.find(item => item.id == 145)
        const heroinPastMonth = previousAnswers.questions.find(item => item.id == 151)
        let opiatesWithdrawlChecked = true
        let heroinWithdrawlChecked = true
        if(opiatesPastMonth && opiatesPastMonth.answers){
            if(opiatesPastMonth.answers.some(({choice_id}) => choice_id == 703)){
                opiatesWithdrawlChecked = true
            }
            else{
                opiatesWithdrawlChecked = false
            }
        }
        if(heroinPastMonth && heroinPastMonth.answers){
            if(heroinPastMonth.answers.some(({choice_id}) => choice_id == 775)){
                heroinWithdrawlChecked = true
            }
            else{
                heroinWithdrawlChecked = false
            }
        }
        if(opiatesWithdrawlChecked &&  heroinWithdrawlChecked){
            deletePreviousAnswers(questionIndex, 126, previousAnswers)
            history.push(`/questions/126`)    
        }
        else{
            const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
            if(!(questionNo4Answer && questionNo4Answer.answers)){
                deletePreviousAnswers(questionIndex, 144, previousAnswers)
                history.push(`/questions/144`)
                return;
            }
            else{
                if(questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                    deletePreviousAnswers(questionIndex, 134, previousAnswers)
                    history.push(`/questions/134`)
                    return;
                }
                else{
                    deletePreviousAnswers(questionIndex, 130, previousAnswers)
                    history.push(`/questions/130`)
                    return;
                }
            }
        }
    }
    else{
        history.push(`/questions/122`)
        return;
    }
  }
  else if(currentQuestionId == 123){
    if(currentAnswer.every(({choice_id}) => choice_id == 1528)){
        const opiatesPastMonth = previousAnswers.questions.find(item => item.id == 145)
        const heroinPastMonth = previousAnswers.questions.find(item => item.id == 151)
        let opiatesWithdrawlChecked = true
        let heroinWithdrawlChecked = true
        if(opiatesPastMonth && opiatesPastMonth.answers){
            if(opiatesPastMonth.answers.some(({choice_id}) => choice_id == 703)){
                opiatesWithdrawlChecked = true
            }
            else{
                opiatesWithdrawlChecked = false
            }
        }
        if(heroinPastMonth && heroinPastMonth.answers){
            if(heroinPastMonth.answers.some(({choice_id}) => choice_id == 775)){
                heroinWithdrawlChecked = true
            }
            else{
                heroinWithdrawlChecked = false
            }
        }
        if(opiatesWithdrawlChecked &&  heroinWithdrawlChecked){
            deletePreviousAnswers(questionIndex, 126, previousAnswers)
            history.push(`/questions/126`)    
        }
        else{
            const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
            if(!(questionNo4Answer && questionNo4Answer.answers)){
                deletePreviousAnswers(questionIndex, 144, previousAnswers)
                history.push(`/questions/144`)
                return;
            }
            else{
                if(questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                    deletePreviousAnswers(questionIndex, 134, previousAnswers)
                    history.push(`/questions/134`)
                    return;
                }
                else{
                    deletePreviousAnswers(questionIndex, 130, previousAnswers)
                    history.push(`/questions/130`)
                    return;
                }
            }
        }
    }
    if(currentAnswer.every(({choice_id}) =>  choice_id == 1528)){
        emptyAnswerArray({124: true, 125: true}, previousAnswers)
        history.push(`/questions/126`)
        return;
    }
    else if(currentAnswer.every(({choice_id}) =>  choice_id == 1530)){
        emptyAnswerArray({124: true }, previousAnswers)
        history.push(`/questions/125`)
        return
    }
    else{
        history.push(`/questions/124`)
    }
  }
  else if(currentQuestionId == 125){
    const opiatesPastMonth = previousAnswers.questions.find(item => item.id == 145)
    const heroinPastMonth = previousAnswers.questions.find(item => item.id == 151)
    let opiatesWithdrawlChecked = true
    let heroinWithdrawlChecked = true
    if(opiatesPastMonth && opiatesPastMonth.answers){
        if(opiatesPastMonth.answers.some(({choice_id}) => choice_id == 703)){
            opiatesWithdrawlChecked = true
        }
        else{
            opiatesWithdrawlChecked = false
        }
    }
    if(heroinPastMonth && heroinPastMonth.answers){
        if(heroinPastMonth.answers.some(({choice_id}) => choice_id == 775)){
            heroinWithdrawlChecked = true
        }
        else{
            heroinWithdrawlChecked = false
        }
    }
    if(opiatesWithdrawlChecked &&  heroinWithdrawlChecked){
        history.push(`/questions/126`)    
    }
    else{
        const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
        if(!(questionNo4Answer && questionNo4Answer.answers)){
            deletePreviousAnswers(questionIndex, 144, previousAnswers)
            history.push(`/questions/144`)
            return;
        }
        else{
            if(questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                emptyAnswerArray({  126: true, 127: true, 128: true,129: true, 130: true, 131: true, 132: true, 133: true}, previousAnswers)
                history.push(`/questions/134`)
            }
            else{
                emptyAnswerArray({  126: true, 127: true, 128: true,129: true}, previousAnswers)
                history.push(`/questions/130`)
            }
        }
    }
}
  else if(currentQuestionId == 126){    
    const questionNo89Answer = previousAnswers.questions.find(item => item.id == 89)
    if(questionNo89Answer && questionNo89Answer.answers){
        if(!questionNo89Answer.answers.some(({choice_id}) => choice_id == 547 || choice_id == 553)){
            const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
            if(!(questionNo4Answer && questionNo4Answer.answers)){
                    deletePreviousAnswers(questionIndex, 144, previousAnswers)
                    history.push(`/questions/144`)
                    return;
            }
            else{
                if(questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                    emptyAnswerArray({  127: true, 128: true,129: true, 130: true, 131: true, 132: true, 133: true}, previousAnswers)
                    history.push(`/questions/134`)
                }
                else{
                    emptyAnswerArray({  127: true, 128: true,129: true}, previousAnswers)
                    history.push(`/questions/130`)
                }
            }
        }
        else{
            const opiatesPastMonth = previousAnswers.questions.find(item => item.id == 145)
            const heroinPastMonth = previousAnswers.questions.find(item => item.id == 151)
            
            let opiatesWithdrawlChecked = true
            let heroinWithdrawlChecked = true
            if(opiatesPastMonth && opiatesPastMonth.answers){
                if(opiatesPastMonth.answers.some(({choice_id}) => choice_id == 703)){
                    opiatesWithdrawlChecked = true
                }
                else{
                    opiatesWithdrawlChecked = false
                }
            }
            if(heroinPastMonth && heroinPastMonth.answers){
                if(heroinPastMonth.answers.some(({choice_id}) => choice_id == 775)){
                    heroinWithdrawlChecked = true
                }
                else{
                    heroinWithdrawlChecked = false
                }
            }
            if(opiatesWithdrawlChecked &&  heroinWithdrawlChecked){
                // if (opioidQuestions(previousAnswers)) {
                //     deletePreviousAnswers(questionIndex, 129, previousAnswers)
                //     history.push(`/questions/129`)
                //     return;
                // }
                // else  {
                //     history.push(`/questions/127`)    
                // }
                opioidQuestions(previousAnswers, history)
            }
            else{
                const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
            if(!(questionNo4Answer && questionNo4Answer.answers)){
                    deletePreviousAnswers(questionIndex, 144, previousAnswers)
                    history.push(`/questions/144`)
                    return;
            }
            else{
                if(questionNo4Answer.answers.some(({choice_id}) => choice_id == 4)){
                    deletePreviousAnswers(questionIndex, 134, previousAnswers)
                    // emptyAnswerArray({  127: true, 128: true,129: true, 130: true, 131: true, 132: true, 133: true}, previousAnswers)
                    history.push(`/questions/134`)
                }
                else{
                    deletePreviousAnswers(questionIndex, 130, previousAnswers)
                    history.push(`/questions/130`)
                }
            }
            }
        }
    }
    else{
        // if (opioidQuestions(previousAnswers)) {
        //     deletePreviousAnswers(questionIndex, 129, previousAnswers)
        //     history.push(`/questions/129`)
        //     return;
        // }
        // history.push(`/questions/127`)
        opioidQuestions(previousAnswers, history)
    }
  }
  else if(currentQuestionId == 127){
    if(currentAnswer.some(({choice_id}) => choice_id == 1558)){
        deletePreviousAnswers(questionIndex, 130, previousAnswers)
        history.push(`/questions/130`)
        return;
    }   
    if(currentAnswer.some(({choice_id}) => choice_id == 1557)){
        deletePreviousAnswers(questionIndex, 129, previousAnswers)
        history.push(`/questions/129`)
        return;
    }
    else{
        history.push(`/questions/128`)
    }
  }
  else if(currentQuestionId == 128){
    const questionNo127Answer = previousAnswers.questions.find(item => item.id == 127).answers
    if(questionNo127Answer.some(({choice_id}) => choice_id == 1556)){
        const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
        if(!(questionNo4Answer && questionNo4Answer.answers)){
                deletePreviousAnswers(questionIndex, 144, previousAnswers)
                history.push(`/questions/144`)
                return;
        }
        deletePreviousAnswers(questionIndex, 130, previousAnswers)
        history.push(`/questions/130`)
        return;
    }
    else{
        history.push(`/questions/129`)
    }
  }
  else if(currentQuestionId == 129){
    const questionNo4Answer = previousAnswers.questions.find(item => item.id == 4)
    if(!(questionNo4Answer && questionNo4Answer.answers)){
            deletePreviousAnswers(questionIndex, 144, previousAnswers)
            history.push(`/questions/144`)
            return;
    }
    else{
        history.push(`/questions/130`)
    }
  }
  else if(currentQuestionId == '144'){
    if(currentAnswer.some(({choice_id}) => choice_id == 1643)){
        deletePreviousAnswers(questionIndex, 134, previousAnswers)
        history.push(`/questions/134`)
        return;
    }
    else if(!currentAnswer.some(({choice_id}) => choice_id == 1633)){
        deletePreviousAnswers(questionIndex, 134, previousAnswers)
        history.push(`/questions/134`)
        return;

    }
    else{
        history.push(`/questions/130`)
    }
  }
  else if(currentQuestionId == '130'){
    const questionNo9 = previousAnswers.questions.find(item => item.id == 4)
    if(questionNo9 && questionNo9.answers && questionNo9.answers.some(({choice_id}) => choice_id != 27)){
        deletePreviousAnswers(questionIndex, 134, previousAnswers)
        history.push(`/questions/134`)
        return;
    }
    else{
        history.push(`/questions/131`)
    }
  }
  else if(currentQuestionId == '134'){
    if(currentAnswer.some(({choice_id}) => choice_id == 1575)){
        history.push(`/questions/completion`)
        return;
    }
    else{
        history.push(`/questions/135`)
    }
  }
  
  else if(aboutTheSameQuestions.some(questionId => questionId == currentQuestionId)){
    const skipLogic = aboutTheSameQuestionsSkip[currentQuestionId]
    if(currentAnswer.some(({choice_id}) => choice_id == skipLogic.skipOptionId)){
        if(skipLogic.skipToId){
            if(currentQuestionId == 110){
                const questionNo90Answer = previousAnswers.questions.find(item => item.id == 90)
                const ageAnswer = previousAnswers.questions.find(item => item.id == 7)
                const age = ageAnswer.answers[0].text
                const lastUsed = {
                    alcohol: ''
                }
                if(questionNo90Answer && questionNo90Answer.answers){
                    questionNo90Answer.answers.forEach(({choice_id, text}) => {
                        if(choice_id == 568){
                            lastUsed.alcohol = text
                            return;
                        }
                    })
                    if(lastUsed.alcohol < age - 1){
                        const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
                        const sliced = question3OptionsChecked.slice(1)
                        for(const option of sliced){
                            if(option.isChecked){
                                deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                                history.push(`/questions/${option.skipToId}`)
                                return;
                            }
                        }
                    }
                }
            }
            if(skipLogic.checkPreviousSymptoms){
                const symptomsAnswer = previousAnswers.questions.find(item => item.id == skipLogic.checkPreviousSymptoms)
                if(symptomsAnswer && symptomsAnswer.answers && symptomsAnswer.answers.length >= 2){
                    const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
                    const sliced = question3OptionsChecked.slice(skipLogic.slice)
                    for(const option of sliced){
                        if(option.isChecked){
                            deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                            history.push(`/questions/${option.skipToId}`)
                            return;
                        }
                    }
                }
            }
            deletePreviousAnswers(questionIndex, skipLogic.skipToId, previousAnswers)
            history.push(`/questions/${skipLogic.skipToId}`)
            return;
        }
        const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)
        const sliced = question3OptionsChecked.slice(skipLogic.slice)
        for(const option of sliced){
            if(option.isChecked){
                deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                history.push(`/questions/${option.skipToId}`)
                return;
            }
        }
    }
    else{     
        deletePreviousAnswers(questionIndex, skipLogic.elseSkip, previousAnswers)
        history.push(`/questions/${skipLogic.elseSkip}`)
    }
  }
  else if(symptomsQuantityCheckQuestions.some(questionId => questionId == currentQuestionId)){

    const symptomsAnswer1 = previousAnswers.questions.find(item => item.id == symptomsQuantityCheckQuestionsSkip[currentQuestionId].symptomsQuantityCheckQuestion)
    const symptomsAnswer2 = previousAnswers.questions.find(item => item.id == symptomsQuantityCheckQuestionsSkip[currentQuestionId].symptomsQuantityCheckQuestion2)
    const symptomsAnswer3 = { id: currentQuestionId, answers: currentAnswer }

      if ((symptomsAnswer1 && symptomsAnswer1.answers && symptomsAnswer1.answers.length >= 2) && (symptomsAnswer2 && symptomsAnswer2.answers && symptomsAnswer2.answers.length >= 2) && (symptomsAnswer3 && symptomsAnswer3.answers && symptomsAnswer3.answers.length >= 2)) {
          const question3OptionsChecked = getQuestion3CheckedOptions(previousAnswers)

          const sliced = question3OptionsChecked.slice(symptomsQuantityCheckQuestionsSkip[currentQuestionId].slice)
          for (const option of sliced) {
              if (option.isChecked) {
                  deletePreviousAnswers(questionIndex, option.skipToId, previousAnswers)
                  history.push(`/questions/${option.skipToId}`)
                  return;
              }
          }
          
      } else {
          history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
      }
  }
  else {
    history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
  }
}

export const questionsAndChoices = [
    {
        "model": "comprisapp.questions",
        "pk": 0,
        "fields": {
            "question_id": "1",
            "sequenceId": 1,
            "question_text": "CMR Code:",
            "survey_id": "294219921",
            "question_type": "open_ended",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z"
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 1,
        "fields": {
            "question_id": "2",
            "sequenceId": 2,
            "question_text": "Could you use some help with (check all that apply):",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "7",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "a substance use concern",
                    "id": "1"
                },
                {
                    "text": "a habit or behavior",
                    "id": "2"
                },
                {
                    "text": "unwanted thoughts or feelings",
                    "id": "3"
                },
                {
                    "text": "something else",
                    "id": "4"
                },
                {
                    "text": "don't know",
                    "id": "6"
                },
                {
                    "text": "no thanks, just checking this out",
                    "id": "7"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 2,
        "fields": {
            "question_id": "3",
            "sequenceId": 3,
            "question_text": "Which substance(s) were a concern this past year (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "23",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "smoking tobacco",
                    "id": "8"
                },
                {
                    "text": "smokeless tobacco (chew / snuff)",
                    "id": "9"
                },
                {
                    "text": "vaping",
                    "id": "10"
                },
                {
                    "text": "alcohol",
                    "id": "11"
                },
                {
                    "text": "Rx opiates (pain)",
                    "id": "12"
                },
                {
                    "text": "Rx benzos (anxiety)",
                    "id": "13"
                },
                {
                    "text": "Rx barbiturates (sleep aids)",
                    "id": "14"
                },
                {
                    "text": "Rx ADHD (stimulants)",
                    "id": "15"
                },
                {
                    "text": "marijuana",
                    "id": "16"
                },
                {
                    "text": "cocaine / crack",
                    "id": "17"
                },
                {
                    "text": "heroin",
                    "id": "18"
                },
                {
                    "text": "crystal meth",
                    "id": "19"
                },
                {
                    "text": "ecstasy / MDMA",
                    "id": "20"
                },
                {
                    "text": "hallucinogens",
                    "id": "21"
                },
                {
                    "text": "inhalants (non-medical)",
                    "id": "22"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 3,
        "fields": {
            "question_id": "4",
            "sequenceId": 4,
            "question_text": "Which habit(s) or behavior may be a concern (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "35",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "OCD (obsessive compulsive)",
                    "id": "25"
                },
                {
                    "text": "eating",
                    "id": "26"
                },
                {
                    "text": "gambling",
                    "id": "27"
                },
                {
                    "text": "over-exercising",
                    "id": "28"
                },
                {
                    "text": "over-spending",
                    "id": "29"
                },
                {
                    "text": "clutter or hoarding",
                    "id": "30"
                },
                {
                    "text": "binge watching",
                    "id": "31"
                },
                {
                    "text": "social media",
                    "id": "32"
                },
                {
                    "text": "video games",
                    "id": "33"
                },
                {
                    "text": "sex / porn",
                    "id": "34"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 4,
        "fields": {
            "question_id": "5",
            "sequenceId": 5,
            "question_text": "Have you ever been diagnosed or struggled with any of these (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "55",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "53",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "anxiety or panic",
                    "id": "38"
                },
                {
                    "text": "depression",
                    "id": "39"
                },
                {
                    "text": "ADHD (includes ADD)",
                    "id": "40"
                },
                {
                    "text": "OCD (obsessive compulsive)",
                    "id": "41"
                },
                {
                    "text": "PTSD",
                    "id": "42"
                },
                {
                    "text": "conduct or oppositional disorder",
                    "id": "43"
                },
                {
                    "text": "bipolar",
                    "id": "44"
                },
                {
                    "text": "schizo-affective",
                    "id": "45"
                },
                {
                    "text": "schizophrenia",
                    "id": "46"
                },
                {
                    "text": "personality",
                    "id": "47"
                },
                {
                    "text": "dissociative",
                    "id": "48"
                },
                {
                    "text": "autism spectrum",
                    "id": "49"
                },
                {
                    "text": "repeat or extended concussion",
                    "id": "50"
                },
                {
                    "text": "traumatic brain injury",
                    "id": "51"
                },
                {
                    "text": "thinking or memory difficulty",
                    "id": "52"
                },
                {
                    "text": "none",
                    "id": "55"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 5,
        "fields": {
            "question_id": "6",
            "sequenceId": 6,
            "question_text": "How ready are you to work on whatever concerns you have in your life?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "startText": "not at all ready",
            "endText": "fully ready",
            "rowSize": 12,
            "options": [
                {
                    "text": "1",
                    "id": "56"
                },
                {
                    "text": "2",
                    "id": "1609"
                },
                {
                    "text": "3",
                    "id": "1610"
                },
                {
                    "text": "4",
                    "id": "1611"
                },
                {
                    "text": "5",
                    "id": "1612"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 6,
        "fields": {
            "question_id": "7",
            "sequenceId": 7,
            "question_text": "How old are you?",
            "bannerText": "Let's start with some background information.",
            "survey_id": "294219921",
            "question_type": "open_ended",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "How old are you?",
                    "id": "57"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 7,
        "fields": {
            "question_id": "8",
            "sequenceId": 8,
            "question_text": "What pronoun do you prefer?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "skip_logic": "",
            "options": [
                {
                    "text": "he, him",
                    "id": "58"
                },
                {
                    "text": "she, her",
                    "id": "59"
                },
                {
                    "text": "they, them",
                    "id": "60"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 8,
        "fields": {
            "question_id": "9",
            "sequenceId": 9,
            "question_text": "Are you currently (pick one):",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "single or dating",
                    "id": "61"
                },
                {
                    "text": "married, partnered or in significant relationship",
                    "id": "62"
                },
                {
                    "text": "recently unpartnered",
                    "id": "63"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 9,
        "fields": {
            "question_id": "10",
            "sequenceId": 10,
            "question_text": "Do you identify as LGBTQ+?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "70",
            "otherOptionText": "other",
            "containsOther": true,
            "options": [
                {
                    "text": "no",
                    "id": "64"
                },
                {
                    "text": "lesbian",
                    "id": "65"
                },
                {
                    "text": "gay",
                    "id": "66"
                },
                {
                    "text": "bisexual",
                    "id": "67"
                },
                {
                    "text": "trans",
                    "id": "68"
                },
                {
                    "text": "queer",
                    "id": "69"
                },
                {
                    "text": "unsure or prefer not to answer",
                    "id": "72"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 10,
        "fields": {
            "question_id": "11",
            "sequenceId": 11,
            "question_text": "What's your race / ethnicity (pick one or more)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "81",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "White",
                    "id": "73"
                },
                {
                    "text": "Hispanic / Latinx",
                    "id": "74"
                },
                {
                    "text": "Black / African American",
                    "id": "75"
                },
                {
                    "text": "Native / Indigenous",
                    "id": "76"
                },
                {
                    "text": "Middle Eastern",
                    "id": "77"
                },
                {
                    "text": "South Asian",
                    "id": "78"
                },
                {
                    "text": "Southeast Asian",
                    "id": "79"
                },
                {
                    "text": "Hawaian / Pacific Islander",
                    "id": "80"
                },
                {
                    "text": "prefer not to share",
                    "id": "83"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 11,
        "fields": {
            "question_id": "12",
            "sequenceId": 12,
            "question_text": "What's your highest education?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "8th grade or less",
                    "id": "84"
                },
                {
                    "text": "some high school",
                    "id": "85"
                },
                {
                    "text": "high school or GED",
                    "id": "86"
                },
                {
                    "text": "some college",
                    "id": "87"
                },
                {
                    "text": "trade school",
                    "id": "88"
                },
                {
                    "text": "associate's",
                    "id": "89"
                },
                {
                    "text": "bachelor's",
                    "id": "90"
                },
                {
                    "text": "post-graduate",
                    "id": "91"
                },
                {
                    "text": "master's",
                    "id": "92"
                },
                {
                    "text": "doctorate",
                    "id": "93"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 12,
        "fields": {
            "question_id": "13",
            "sequenceId": 13,
            "question_text": "What's your current living situation?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "106",
            "otherOptionText": "other",
            "containsOther": true,
            "options": [
                {
                    "text": "house or apartment",
                    "id": "94"
                },
                {
                    "text": "senior building / retirement community",
                    "id": "95"
                },
                {
                    "text": "assisted living",
                    "id": "96"
                },
                {
                    "text": "long term care facility",
                    "id": "97"
                },
                {
                    "text": "group home",
                    "id": "98"
                },
                {
                    "text": "hospital or inpatient facility",
                    "id": "99"
                },
                {
                    "text": "physical rehab facility",
                    "id": "100"
                },
                {
                    "text": "residential treatment",
                    "id": "101"
                },
                {
                    "text": "recovery home",
                    "id": "102"
                },
                {
                    "text": "couch surfing / temporary stay",
                    "id": "103"
                },
                {
                    "text": "jail or detention",
                    "id": "104"
                },
                {
                    "text": "unhoused",
                    "id": "105"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 13,
        "fields": {
            "question_id": "14",
            "sequenceId": 14,
            "question_text": "How long have you been there?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "less than a month",
                    "id": "108"
                },
                {
                    "text": "1-3 months",
                    "id": "109"
                },
                {
                    "text": "4-12 months",
                    "id": "110"
                },
                {
                    "text": "1-2 years",
                    "id": "111"
                },
                {
                    "text": "3-5 years",
                    "id": "112"
                },
                {
                    "text": "more than 5 years",
                    "id": "113"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 14,
        "fields": {
            "question_id": "15",
            "sequenceId": 15,
            "question_text": "Do you have a future housing option?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "114"
                },
                {
                    "text": "no",
                    "id": "115"
                },
                {
                    "text": "unsure",
                    "id": "116"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 15,
        "fields": {
            "question_id": "16",
            "sequenceId": 16,
            "question_text": "I feel safe where I live.",
            "bannerText": "Would you agree with these statements?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "117"
                },
                {
                    "text": "disagree",
                    "id": "118"
                },
                {
                    "text": "unsure",
                    "id": "119"
                },
                {
                    "text": "agree",
                    "id": "120"
                },
                {
                    "text": "strongly agree",
                    "id": "121"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 16,
        "fields": {
            "question_id": "17",
            "sequenceId": 17,
            "question_text": "My income is stable compared to my expenses.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "122"
                },
                {
                    "text": "disagree",
                    "id": "123"
                },
                {
                    "text": "unsure",
                    "id": "124"
                },
                {
                    "text": "agree",
                    "id": "125"
                },
                {
                    "text": "strongly agree",
                    "id": "126"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 17,
        "fields": {
            "question_id": "18",
            "sequenceId": 18,
            "question_text": "I live in a substance-safe space (no nicotine or problem substances).",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "127"
                },
                {
                    "text": "disagree",
                    "id": "128"
                },
                {
                    "text": "unsure",
                    "id": "129"
                },
                {
                    "text": "agree",
                    "id": "130"
                },
                {
                    "text": "strongly agree",
                    "id": "131"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 18,
        "fields": {
            "question_id": "19",
            "sequenceId": 19,
            "question_text": "How many <b>other</b> people do you live with?",
            "survey_id": "294219921",
            "question_type": "open_ended",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "How many other people do you live with?",
                    "id": "132"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 19,
        "fields": {
            "question_id": "20",
            "sequenceId": 20,
            "question_text": "Is your combined household income less than $100K a year?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "133"
                },
                {
                    "text": "no",
                    "id": "134"
                },
                {
                    "text": "prefer not to share",
                    "id": "135"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 20,
        "fields": {
            "question_id": "21",
            "sequenceId": 21,
            "question_text": "What is your combined household income?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "no current income",
                    "id": "136"
                },
                {
                    "text": "less than $20K",
                    "id": "137"
                },
                {
                    "text": "$20-29K",
                    "id": "138"
                },
                {
                    "text": "$30-49K",
                    "id": "139"
                },
                {
                    "text": "$50-74K",
                    "id": "140"
                },
                {
                    "text": "$75-99K",
                    "id": "141"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 21,
        "fields": {
            "question_id": "22",
            "sequenceId": 22,
            "question_text": "Do you pay rent or own your place?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "pay rent",
                    "id": "142"
                },
                {
                    "text": "own / pay a mortgage",
                    "id": "143"
                },
                {
                    "text": "housed by family or others",
                    "id": "144"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 22,
        "fields": {
            "question_id": "23",
            "sequenceId": 23,
            "question_text": "Do you have health insurance?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "145"
                },
                {
                    "text": "no",
                    "id": "146"
                },
                {
                    "text": "unsure",
                    "id": "147"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 23,
        "fields": {
            "question_id": "24",
            "sequenceId": 24,
            "question_text": "What's your current work situation?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "full time",
                    "id": "148"
                },
                {
                    "text": "part time",
                    "id": "149"
                },
                {
                    "text": "retired",
                    "id": "150"
                },
                {
                    "text": "disabled",
                    "id": "151"
                },
                {
                    "text": "in school / not working",
                    "id": "152"
                },
                {
                    "text": "stay-at-home parent",
                    "id": "153"
                },
                {
                    "text": "caring for older / disabled family",
                    "id": "154"
                },
                {
                    "text": "unemployed",
                    "id": "155"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 24,
        "fields": {
            "question_id": "25",
            "sequenceId": 25,
            "question_text": "How come you're unemployed?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "between jobs / looking for work",
                    "id": "156"
                },
                {
                    "text": "health reasons",
                    "id": "157"
                },
                {
                    "text": "personal reasons",
                    "id": "158"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 25,
        "fields": {
            "question_id": "26",
            "sequenceId": 26,
            "question_text": "Do the reasons include (check all that apply):",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "162",
            "otherOptionText": "other",
            "noneId": "164",
            "options": [
                {
                    "text": "substance misuse",
                    "id": "159"
                },
                {
                    "text": "unhealthy habit(s)",
                    "id": "160"
                },
                {
                    "text": "mental illness",
                    "id": "161"
                },
                {
                    "text": "none of these",
                    "id": "164"
                },
                {
                    "text": "unsure or prefer not to answer",
                    "id": "165"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 26,
        "fields": {
            "question_id": "27",
            "sequenceId": 27,
            "question_text": "How long have you been unemployed?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "less than 6 months",
                    "id": "166"
                },
                {
                    "text": "6-12 months",
                    "id": "167"
                },
                {
                    "text": "1-2 years",
                    "id": "168"
                },
                {
                    "text": "3-5 years",
                    "id": "169"
                },
                {
                    "text": "more than 5 years",
                    "id": "170"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 27,
        "fields": {
            "question_id": "28",
            "sequenceId": 28,
            "bannerText": "Tell us about your daily health habits.",
            "question_text": "I eat healthy food.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "171"
                },
                {
                    "text": "disagree",
                    "id": "172"
                },
                {
                    "text": "unsure",
                    "id": "173"
                },
                {
                    "text": "agree",
                    "id": "174"
                },
                {
                    "text": "strongly agree",
                    "id": "175"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 28,
        "fields": {
            "question_id": "29",
            "sequenceId": 29,
            "question_text": "I exercise routinely.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "176"
                },
                {
                    "text": "disagree",
                    "id": "177"
                },
                {
                    "text": "unsure",
                    "id": "178"
                },
                {
                    "text": "agree",
                    "id": "179"
                },
                {
                    "text": "strongly agree",
                    "id": "180"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 29,
        "fields": {
            "question_id": "30",
            "sequenceId": 30,
            "question_text": "I get 7 or more hours of sleep daily.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "181"
                },
                {
                    "text": "disagree",
                    "id": "182"
                },
                {
                    "text": "unsure",
                    "id": "183"
                },
                {
                    "text": "agree",
                    "id": "184"
                },
                {
                    "text": "strongly agree",
                    "id": "185"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 30,
        "fields": {
            "question_id": "31",
            "sequenceId": 31,
            "question_text": "I practice mindfulness / meditation.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "186"
                },
                {
                    "text": "disagree",
                    "id": "187"
                },
                {
                    "text": "unsure",
                    "id": "188"
                },
                {
                    "text": "agree",
                    "id": "189"
                },
                {
                    "text": "strongly agree",
                    "id": "190"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 31,
        "fields": {
            "question_id": "32",
            "sequenceId": 32,
            "question_text": "I practice daily gratitude.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "191"
                },
                {
                    "text": "disagree",
                    "id": "192"
                },
                {
                    "text": "unsure",
                    "id": "193"
                },
                {
                    "text": "agree",
                    "id": "194"
                },
                {
                    "text": "strongly agree",
                    "id": "195"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 32,
        "fields": {
            "question_id": "33",
            "sequenceId": 33,
            "question_text": "I'm well-being focused.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "196"
                },
                {
                    "text": "disagree",
                    "id": "197"
                },
                {
                    "text": "unsure",
                    "id": "198"
                },
                {
                    "text": "agree",
                    "id": "199"
                },
                {
                    "text": "strongly agree",
                    "id": "200"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 33,
        "fields": {
            "question_id": "34",
            "sequenceId": 34,
            "bannerText": "How do you approach challenges?",
            "question_text": "I have meaning and purpose.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "201"
                },
                {
                    "text": "disagree",
                    "id": "202"
                },
                {
                    "text": "unsure",
                    "id": "203"
                },
                {
                    "text": "agree",
                    "id": "204"
                },
                {
                    "text": "strongly agree",
                    "id": "205"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 34,
        "fields": {
            "question_id": "35",
            "sequenceId": 35,
            "question_text": "I have clear values to guide me.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "206"
                },
                {
                    "text": "disagree",
                    "id": "207"
                },
                {
                    "text": "unsure",
                    "id": "208"
                },
                {
                    "text": "agree",
                    "id": "209"
                },
                {
                    "text": "strongly agree",
                    "id": "210"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 35,
        "fields": {
            "question_id": "36",
            "sequenceId": 36,
            "question_text": "I'm a positive person.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "211"
                },
                {
                    "text": "disagree",
                    "id": "212"
                },
                {
                    "text": "unsure",
                    "id": "213"
                },
                {
                    "text": "agree",
                    "id": "214"
                },
                {
                    "text": "strongly agree",
                    "id": "215"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 36,
        "fields": {
            "question_id": "37",
            "sequenceId": 37,
            "question_text": "I'd say I'm a spiritual person.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "216"
                },
                {
                    "text": "disagree",
                    "id": "217"
                },
                {
                    "text": "unsure",
                    "id": "218"
                },
                {
                    "text": "agree",
                    "id": "219"
                },
                {
                    "text": "strongly agree",
                    "id": "220"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 37,
        "fields": {
            "question_id": "38",
            "sequenceId": 38,
            "question_text": "I face my fears and challenges.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "221"
                },
                {
                    "text": "disagree",
                    "id": "222"
                },
                {
                    "text": "unsure",
                    "id": "223"
                },
                {
                    "text": "agree",
                    "id": "224"
                },
                {
                    "text": "strongly agree",
                    "id": "225"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 38,
        "fields": {
            "question_id": "39",
            "sequenceId": 39,
            "question_text": "I forgive self and others.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "226"
                },
                {
                    "text": "disagree",
                    "id": "227"
                },
                {
                    "text": "unsure",
                    "id": "228"
                },
                {
                    "text": "agree",
                    "id": "229"
                },
                {
                    "text": "strongly agree",
                    "id": "230"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 39,
        "fields": {
            "question_id": "40",
            "sequenceId": 40,
            "question_text": "I've got a good sense of humor.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "231"
                },
                {
                    "text": "disagree",
                    "id": "232"
                },
                {
                    "text": "unsure",
                    "id": "233"
                },
                {
                    "text": "agree",
                    "id": "234"
                },
                {
                    "text": "strongly agree",
                    "id": "235"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 40,
        "fields": {
            "question_id": "41",
            "sequenceId": 41,
            "bannerText": "How are your relationships going?",
            "question_text": "I have a supportive partner.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "236"
                },
                {
                    "text": "disagree",
                    "id": "237"
                },
                {
                    "text": "unsure",
                    "id": "238"
                },
                {
                    "text": "agree",
                    "id": "239"
                },
                {
                    "text": "strongly agree",
                    "id": "240"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 41,
        "fields": {
            "question_id": "42",
            "sequenceId": 42,
            "question_text": "I have supportive family.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "241"
                },
                {
                    "text": "disagree",
                    "id": "242"
                },
                {
                    "text": "unsure",
                    "id": "243"
                },
                {
                    "text": "agree",
                    "id": "244"
                },
                {
                    "text": "strongly agree",
                    "id": "245"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 42,
        "fields": {
            "question_id": "43",
            "sequenceId": 43,
            "question_text": "I belong to a supportive community.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "246"
                },
                {
                    "text": "disagree",
                    "id": "247"
                },
                {
                    "text": "unsure",
                    "id": "248"
                },
                {
                    "text": "agree",
                    "id": "249"
                },
                {
                    "text": "strongly agree",
                    "id": "250"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 43,
        "fields": {
            "question_id": "44",
            "sequenceId": 44,
            "question_text": "I have a trusted healthcare provider.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "251"
                },
                {
                    "text": "disagree",
                    "id": "252"
                },
                {
                    "text": "unsure",
                    "id": "253"
                },
                {
                    "text": "agree",
                    "id": "254"
                },
                {
                    "text": "strongly agree",
                    "id": "255"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 44,
        "fields": {
            "question_id": "45",
            "sequenceId": 45,
            "question_text": "I have positive role model(s).",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "256"
                },
                {
                    "text": "disagree",
                    "id": "257"
                },
                {
                    "text": "unsure",
                    "id": "258"
                },
                {
                    "text": "agree",
                    "id": "259"
                },
                {
                    "text": "strongly agree",
                    "id": "260"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 45,
        "fields": {
            "question_id": "46",
            "sequenceId": 46,
            "question_text": "I share myself honestly and openly with others.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "261"
                },
                {
                    "text": "disagree",
                    "id": "262"
                },
                {
                    "text": "unsure",
                    "id": "263"
                },
                {
                    "text": "agree",
                    "id": "264"
                },
                {
                    "text": "strongly agree",
                    "id": "265"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 46,
        "fields": {
            "question_id": "47",
            "sequenceId": 47,
            "question_text": "I am kind and helpful to others.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "266"
                },
                {
                    "text": "disagree",
                    "id": "267"
                },
                {
                    "text": "unsure",
                    "id": "268"
                },
                {
                    "text": "agree",
                    "id": "269"
                },
                {
                    "text": "strongly agree",
                    "id": "270"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 47,
        "fields": {
            "question_id": "48",
            "sequenceId": 48,
            "question_text": "I'm a good listener.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "271"
                },
                {
                    "text": "disagree",
                    "id": "272"
                },
                {
                    "text": "unsure",
                    "id": "273"
                },
                {
                    "text": "agree",
                    "id": "274"
                },
                {
                    "text": "strongly agree",
                    "id": "275"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 48,
        "fields": {
            "question_id": "49",
            "sequenceId": 49,
            "bannerText": "How about your current life skills?",
            "question_text": "I have many interests and hobbies.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree ",
                    "id": "276"
                },
                {
                    "text": "disagree ",
                    "id": "277"
                },
                {
                    "text": "unsure ",
                    "id": "278"
                },
                {
                    "text": "agree ",
                    "id": "279"
                },
                {
                    "text": "strongly agree ",
                    "id": "280"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 49,
        "fields": {
            "question_id": "50",
            "sequenceId": 50,
            "question_text": "I know and use my strengths.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "281"
                },
                {
                    "text": "disagree",
                    "id": "282"
                },
                {
                    "text": "unsure",
                    "id": "283"
                },
                {
                    "text": "agree",
                    "id": "284"
                },
                {
                    "text": "strongly agree",
                    "id": "285"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 50,
        "fields": {
            "question_id": "51",
            "sequenceId": 51,
            "question_text": "I'm productive / make a difference / contributing.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "286"
                },
                {
                    "text": "disagree",
                    "id": "287"
                },
                {
                    "text": "unsure",
                    "id": "288"
                },
                {
                    "text": "agree",
                    "id": "289"
                },
                {
                    "text": "strongly agree",
                    "id": "290"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 51,
        "fields": {
            "question_id": "52",
            "sequenceId": 52,
            "question_text": "I have solid problem-solving skills.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "291"
                },
                {
                    "text": "disagree",
                    "id": "292"
                },
                {
                    "text": "unsure",
                    "id": "293"
                },
                {
                    "text": "agree",
                    "id": "294"
                },
                {
                    "text": "strongly agree",
                    "id": "295"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 52,
        "fields": {
            "question_id": "53",
            "sequenceId": 53,
            "question_text": "I manage stressful situations well.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "296"
                },
                {
                    "text": "disagree",
                    "id": "297"
                },
                {
                    "text": "unsure",
                    "id": "298"
                },
                {
                    "text": "agree",
                    "id": "299"
                },
                {
                    "text": "strongly agree",
                    "id": "300"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 53,
        "fields": {
            "question_id": "54",
            "sequenceId": 54,
            "question_text": "Are you pregnant?",
            "bannerText": "There may be some challenges you're dealing with. Let's look at them as well.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "no",
                    "id": "301"
                },
                {
                    "text": "yes",
                    "id": "302"
                },
                {
                    "text": "unsure",
                    "id": "303"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 54,
        "fields": {
            "question_id": "55",
            "sequenceId": 55,
            "question_text": "Have you had any of these health problems (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "330",
            "noneId": "332",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "asthma",
                    "id": "304"
                },
                {
                    "text": "arthritis",
                    "id": "305"
                },
                {
                    "text": "chronic pain",
                    "id": "306"
                },
                {
                    "text": "diabetes",
                    "id": "307"
                },
                {
                    "text": "obesity",
                    "id": "308"
                },
                {
                    "text": "gastritis",
                    "id": "309"
                },
                {
                    "text": "GI bleed",
                    "id": "310"
                },
                {
                    "text": "pancreatitis",
                    "id": "311"
                },
                {
                    "text": "DTs (withdrawal delusions)",
                    "id": "312"
                },
                {
                    "text": "seizures (alcohol-related)",
                    "id": "313"
                },
                {
                    "text": "COPD",
                    "id": "314"
                },
                {
                    "text": "high B/P",
                    "id": "315"
                },
                {
                    "text": "heart disease",
                    "id": "316"
                },
                {
                    "text": "kidney disease",
                    "id": "317"
                },
                {
                    "text": "liver disease",
                    "id": "318"
                },
                {
                    "text": "hep B",
                    "id": "319"
                },
                {
                    "text": "hep C",
                    "id": "320"
                },
                {
                    "text": "HIV",
                    "id": "321"
                },
                {
                    "text": "STDs",
                    "id": "322"
                },
                {
                    "text": "IV drug misuse",
                    "id": "323"
                },
                {
                    "text": "IV drug infections",
                    "id": "324"
                },
                {
                    "text": "endocarditis",
                    "id": "325"
                },
                {
                    "text": "osteomyelitis",
                    "id": "326"
                },
                {
                    "text": "cancer",
                    "id": "327"
                },
                {
                    "text": "stroke",
                    "id": "328"
                },
                {
                    "text": "memory loss or dementia",
                    "id": "329"
                },
                {
                    "text": "none",
                    "id": "332"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 55,
        "fields": {
            "question_id": "56",
            "sequenceId": 56,
            "question_text": "We worried our food would run out before we got money to buy more.",
            "bannerText": "During the past 12 months, were these true for you?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "often true",
                    "id": "333"
                },
                {
                    "text": "sometimes true",
                    "id": "334"
                },
                {
                    "text": "never true",
                    "id": "335"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 56,
        "fields": {
            "question_id": "57",
            "sequenceId": 57,
            "question_text": "The food we bought didn't last, and we didn't have money to get more.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "often true",
                    "id": "336"
                },
                {
                    "text": "sometimes true",
                    "id": "337"
                },
                {
                    "text": "never true",
                    "id": "338"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 57,
        "fields": {
            "question_id": "58",
            "sequenceId": 58,
            "question_text": "How tall are you?",
            "survey_id": "294219921",
            "question_type": "multi_open_ended",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "______ feet",
                    "id": "339"
                },
                {
                    "text": "______ inches",
                    "id": "340"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 58,
        "fields": {
            "question_id": "59",
            "sequenceId": 59,
            "question_text": "How much do you weigh? (this helps us support your health needs)",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "containsOther": true,
            "otherOPtionId": "341",
            "otherOptionText": "lbs",
            "options": [
                {
                    "text": "prefer not to share",
                    "id": "342"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 59,
        "fields": {
            "question_id": "143",
            "sequenceId": 60,
            "question_text": "Have you ever been diagnosed or struggled with any of these (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "1628",
            "noneId": "1630",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "anxiety or panic",
                    "id": "1613"
                },
                {
                    "text": "depression",
                    "id": "1614"
                },
                {
                    "text": "ADHD (includes ADD)",
                    "id": "1615"
                },
                {
                    "text": "OCD (obsessive compulsive)",
                    "id": "1616"
                },
                {
                    "text": "PTSD",
                    "id": "1617"
                },
                {
                    "text": "conduct or oppositional disorder",
                    "id": "1618"
                },
                {
                    "text": "bipolar",
                    "id": "1619"
                },
                {
                    "text": "schizo-affective",
                    "id": "1620"
                },
                {
                    "text": "schizophrenia",
                    "id": "1621"
                },
                {
                    "text": "personality",
                    "id": "1622"
                },
                {
                    "text": "dissociative",
                    "id": "1623"
                },
                {
                    "text": "autism spectrum",
                    "id": "1624"
                },
                {
                    "text": "repeat or extended concussion",
                    "id": "1625"
                },
                {
                    "text": "traumatic brain injury",
                    "id": "1626"
                },
                {
                    "text": "thinking or memory difficulty",
                    "id": "1627"
                },
                {
                    "text": "none",
                    "id": "1630"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 60,
        "fields": {
            "question_id": "60",
            "sequenceId": 61,
            "question_text": "Are you currently taking medication for a mental health condition?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "no",
                    "id": "343"
                },
                {
                    "text": "yes",
                    "id": "344"
                },
                {
                    "text": "yes, but don't know the name",
                    "id": "345"
                },
                {
                    "text": "prefer not to share",
                    "id": "346"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 61,
        "fields": {
            "question_id": "61",
            "sequenceId": 62,
            "question_text": "Which medication?",
            "survey_id": "294219921",
            "question_type": "multi_open_ended",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "1medication:",
                    "id": "347"
                },
                {
                    "text": "1amount / dose:",
                    "id": "348"
                },
                {
                    "text": "1when taken:",
                    "id": "349"
                },
                {
                    "text": "2medication:",
                    "id": "350"
                },
                {
                    "text": "2amount / dose:",
                    "id": "351"
                },
                {
                    "text": "2when taken:",
                    "id": "352"
                },
                {
                    "text": "3medication:",
                    "id": "353"
                },
                {
                    "text": "3amount / dose:",
                    "id": "354"
                },
                {
                    "text": "3when taken:",
                    "id": "355"
                },
                {
                    "text": "4medication:",
                    "id": "356"
                },
                {
                    "text": "4amount / dose:",
                    "id": "357"
                },
                {
                    "text": "4when taken:",
                    "id": "358"
                },
                {
                    "text": "5medication:",
                    "id": "359"
                },
                {
                    "text": "5amount / dose:",
                    "id": "360"
                },
                {
                    "text": "5when taken:",
                    "id": "361"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 62,
        "fields": {
            "question_id": "62",
            "sequenceId": 63,
            "question_text": "Were you ever hospitalized for a mental health concern?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "no",
                    "id": "362"
                },
                {
                    "text": "once",
                    "id": "363"
                },
                {
                    "text": "2-3 times",
                    "id": "364"
                },
                {
                    "text": "4-9 times",
                    "id": "365"
                },
                {
                    "text": "10 or more times",
                    "id": "366"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 63,
        "fields": {
            "question_id": "63",
            "sequenceId": 64,
            "question_text": "How recently?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "in the past 30 days",
                    "id": "367"
                },
                {
                    "text": "in the past 3 months",
                    "id": "368"
                },
                {
                    "text": "in the past year",
                    "id": "369"
                },
                {
                    "text": "1-3 years ago",
                    "id": "370"
                },
                {
                    "text": "4-9 years ago",
                    "id": "371"
                },
                {
                    "text": "10+ years ago",
                    "id": "372"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 64,
        "fields": {
            "question_id": "64",
            "sequenceId": 65,
            "question_text": "In the <b>past 2 weeks</b>, how often were you bothered by little interest or pleasure in doing things?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "373"
                },
                {
                    "text": "several days",
                    "id": "374"
                },
                {
                    "text": "more than half the days",
                    "id": "375"
                },
                {
                    "text": "nearly every day",
                    "id": "376"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 65,
        "fields": {
            "question_id": "65",
            "sequenceId": 66,
            "question_text": "In the <b>past 2 weeks</b>, how often were you bothered by feeling down, depressed or hopeless",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "377"
                },
                {
                    "text": "several days",
                    "id": "378"
                },
                {
                    "text": "more than half the days",
                    "id": "379"
                },
                {
                    "text": "nearly every day",
                    "id": "380"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 66,
        "fields": {
            "question_id": "66",
            "sequenceId": 67,
            "question_text": "In the <b>past 2 weeks</b>, how often were you bothered by suicidal (hurting self) thoughts or behaviors",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "381"
                },
                {
                    "text": "several days",
                    "id": "382"
                },
                {
                    "text": "more than half the days",
                    "id": "383"
                },
                {
                    "text": "nearly every day",
                    "id": "384"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 67,
        "fields": {
            "question_id": "67",
            "sequenceId": 68,
            "question_text": "In the <b>past 2 weeks</b>, how often were you bothered by homicidal (hurting someone else) thoughts or behaviors",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "385"
                },
                {
                    "text": "several days",
                    "id": "386"
                },
                {
                    "text": "more than half the days",
                    "id": "387"
                },
                {
                    "text": "nearly every day",
                    "id": "388"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 68,
        "fields": {
            "question_id": "68",
            "sequenceId": 69,
            "question_text": "Have you had any of these life traumas (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "412",
            "noneId": "414",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "life-altering accident / injury",
                    "id": "389"
                },
                {
                    "text": "life-altering illness",
                    "id": "390"
                },
                {
                    "text": "currently wheelchair aided",
                    "id": "391"
                },
                {
                    "text": "currently bed confined",
                    "id": "392"
                },
                {
                    "text": "divorce",
                    "id": "393"
                },
                {
                    "text": "loss of a child",
                    "id": "394"
                },
                {
                    "text": "loss of a loved one",
                    "id": "395"
                },
                {
                    "text": "chronic loneliness",
                    "id": "396"
                },
                {
                    "text": "poverty",
                    "id": "397"
                },
                {
                    "text": "not enough food",
                    "id": "398"
                },
                {
                    "text": "currently no transportation options",
                    "id": "399"
                },
                {
                    "text": "mentally ill parent",
                    "id": "400"
                },
                {
                    "text": "neglectful / uncaring parent",
                    "id": "401"
                },
                {
                    "text": "parent being abused",
                    "id": "402"
                },
                {
                    "text": "parent in jail / prison",
                    "id": "403"
                },
                {
                    "text": "abandoned by parent",
                    "id": "404"
                },
                {
                    "text": "emotional abuse",
                    "id": "405"
                },
                {
                    "text": "physical abuse",
                    "id": "406"
                },
                {
                    "text": "sexual abuse",
                    "id": "407"
                },
                {
                    "text": "foster care / orphaned",
                    "id": "408"
                },
                {
                    "text": "natural disaster / fire",
                    "id": "409"
                },
                {
                    "text": "war / terrorism",
                    "id": "410"
                },
                {
                    "text": "crime / violence",
                    "id": "411"
                },
                {
                    "text": "none",
                    "id": "414"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 69,
        "fields": {
            "question_id": "69",
            "sequenceId": 70,
            "question_text": "Did any of your biological relatives have a substance problem (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice_with_radio",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noneId": "432",
            "options": [
                {
                    "text": "father",
                    "id": "415"
                },
                {
                    "text": "mother",
                    "id": "416"
                },
                {
                    "text": "identical twin, triplet, etc.",
                    "id": "1663"
                },
                {
                    "text": "sibling",
                    "id": "sibling",
                    "question": "How many siblings?",
                    "showSubQuestion": false,
                    "subOptions": [
                        {
                            "text": "1",
                            "id": "417"
                        },
                        {
                            "text": "2",
                            "id": "418"
                        },
                        {
                            "text": "3 or more",
                            "id": "419"
                        }
                    ]
                },
                {
                    "text": "child",
                    "id": "child",
                    "question": "How many children?",
                    "showSubQuestion": false,
                    "subOptions": [
                        {
                            "text": "1",
                            "id": "420"
                        },
                        {
                            "text": "2",
                            "id": "421"
                        },
                        {
                            "text": "3 or more",
                            "id": "422"
                        }
                    ]
                },
                {
                    "text": "grandparent, aunt or uncle",
                    "id": "grandparent",
                    "question": "How many grandparents, aunts, or uncles?",
                    "showSubQuestion": false,
                    "subOptions": [
                        {
                            "text": "1",
                            "id": "423"
                        },
                        {
                            "text": "2",
                            "id": "424"
                        },
                        {
                            "text": "3 or more",
                            "id": "425"
                        }
                    ]
                },
                {
                    "text": "niece or nephew",
                    "id": "aunt_or_uncle",
                    "question": "How many nieces or nephews?",
                    "showSubQuestion": false,
                    "subOptions": [
                        {
                            "text": "1",
                            "id": "426"
                        },
                        {
                            "text": "2",
                            "id": "427"
                        },
                        {
                            "text": "3 or more",
                            "id": "428"
                        }
                    ]
                },
                {
                    "text": "first cousin",
                    "id": "cousin_niece_nephew",
                    "question": "How many first cousins?",
                    "showSubQuestion": false,
                    "subOptions": [
                        {
                            "text": "1",
                            "id": "429"
                        },
                        {
                            "text": "2",
                            "id": "430"
                        },
                        {
                            "text": "3 or more",
                            "id": "431"
                        }
                    ]
                },
                {
                    "text": "none or don't know",
                    "id": "432"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 70,
        "fields": {
            "question_id": "70",
            "sequenceId": 71,
            "question_text": "How many died from their substance problem?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noneId": "450",
            "rowSize": 1,
            "options": [
                {
                    "text": "none or don't know",
                    "id": "433"
                },
                {
                    "text": "1",
                    "id": "434"
                },
                {
                    "text": "2-3",
                    "id": "435"
                },
                {
                    "text": "4 or more",
                    "id": "436"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 71,
        "fields": {
            "question_id": "71",
            "sequenceId": 72,
            "question_text": "Did you grow up in a household that included any of these behaviors (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noneId": "464",
            "options": [
                {
                    "text": "smoking tobacco",
                    "id": "451"
                },
                {
                    "text": "alcohol misuse",
                    "id": "452"
                },
                {
                    "type": "input",
                    "text": "other substance(s)",
                    "id": "453"
                },
                {
                    "text": "OCD (obsessive-compulsive)",
                    "id": "455"
                },
                {
                    "text": "eating disorder",
                    "id": "456"
                },
                {
                    "text": "gambling",
                    "id": "457"
                },
                {
                    "text": "over-spending",
                    "id": "458"
                },
                {
                    "text": "clutter or hoarding",
                    "id": "459"
                },
                {
                    "text": "binge watching",
                    "id": "460"
                },
                {
                    "text": "sex / porn",
                    "id": "461"
                },
                {
                    "type": "input",
                    "text": "other",
                    "id": "462"
                },
                {
                    "text": "none of these",
                    "id": "464"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 72,
        "fields": {
            "question_id": "72",
            "sequenceId": 73,
            "question_text": "I am content",
            "bannerText": "How much do you agree with these statements?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "465"
                },
                {
                    "text": "disagree",
                    "id": "466"
                },
                {
                    "text": "unsure",
                    "id": "467"
                },
                {
                    "text": "agree",
                    "id": "468"
                },
                {
                    "text": "strongly agree",
                    "id": "469"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 73,
        "fields": {
            "question_id": "73",
            "sequenceId": 74,
            "question_text": "I often don't think things through before I speak",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "470"
                },
                {
                    "text": "disagree",
                    "id": "471"
                },
                {
                    "text": "unsure",
                    "id": "472"
                },
                {
                    "text": "agree",
                    "id": "473"
                },
                {
                    "text": "strongly agree",
                    "id": "474"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 74,
        "fields": {
            "question_id": "74",
            "sequenceId": 75,
            "question_text": "I am happy",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "475"
                },
                {
                    "text": "disagree",
                    "id": "476"
                },
                {
                    "text": "unsure",
                    "id": "477"
                },
                {
                    "text": "agree",
                    "id": "478"
                },
                {
                    "text": "strongly agree",
                    "id": "479"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 75,
        "fields": {
            "question_id": "75",
            "sequenceId": 76,
            "question_text": "I have faith that my future holds great promise",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "480"
                },
                {
                    "text": "disagree",
                    "id": "481"
                },
                {
                    "text": "unsure",
                    "id": "482"
                },
                {
                    "text": "agree",
                    "id": "483"
                },
                {
                    "text": "strongly agree",
                    "id": "484"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 76,
        "fields": {
            "question_id": "76",
            "sequenceId": 77,
            "question_text": "I usually act without stopping to think",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "485"
                },
                {
                    "text": "disagree",
                    "id": "486"
                },
                {
                    "text": "unsure",
                    "id": "487"
                },
                {
                    "text": "agree",
                    "id": "488"
                },
                {
                    "text": "strongly agree",
                    "id": "489"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 77,
        "fields": {
            "question_id": "77",
            "sequenceId": 78,
            "question_text": "I feel proud of my accomplishments",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "490"
                },
                {
                    "text": "disagree",
                    "id": "491"
                },
                {
                    "text": "unsure",
                    "id": "492"
                },
                {
                    "text": "agree",
                    "id": "493"
                },
                {
                    "text": "strongly agree",
                    "id": "494"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 78,
        "fields": {
            "question_id": "78",
            "sequenceId": 79,
            "question_text": "I feel that I'm a failure",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "495"
                },
                {
                    "text": "disagree",
                    "id": "496"
                },
                {
                    "text": "unsure",
                    "id": "497"
                },
                {
                    "text": "agree",
                    "id": "498"
                },
                {
                    "text": "strongly agree",
                    "id": "499"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 79,
        "fields": {
            "question_id": "79",
            "sequenceId": 80,
            "question_text": "Generally, I am an impulsive person",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "500"
                },
                {
                    "text": "disagree",
                    "id": "501"
                },
                {
                    "text": "unsure",
                    "id": "502"
                },
                {
                    "text": "agree",
                    "id": "503"
                },
                {
                    "text": "strongly agree",
                    "id": "504"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 80,
        "fields": {
            "question_id": "80",
            "sequenceId": 81,
            "question_text": "I am very enthusiastic about my future",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "505"
                },
                {
                    "text": "disagree",
                    "id": "506"
                },
                {
                    "text": "unsure",
                    "id": "507"
                },
                {
                    "text": "agree",
                    "id": "508"
                },
                {
                    "text": "strongly agree",
                    "id": "509"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 81,
        "fields": {
            "question_id": "81",
            "sequenceId": 82,
            "question_text": "I often involve myself in situations I later regret",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "510"
                },
                {
                    "text": "disagree",
                    "id": "511"
                },
                {
                    "text": "unsure",
                    "id": "512"
                },
                {
                    "text": "agree",
                    "id": "513"
                },
                {
                    "text": "strongly agree",
                    "id": "514"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 82,
        "fields": {
            "question_id": "82",
            "sequenceId": 83,
            "question_text": "feeling nervous, anxious, or on edge",
            "bannerText": "In the past 2 weeks, how often have you experienced the following:",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "515"
                },
                {
                    "text": "several days",
                    "id": "516"
                },
                {
                    "text": "more than half the days",
                    "id": "517"
                },
                {
                    "text": "nearly every day",
                    "id": "518"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 83,
        "fields": {
            "question_id": "83",
            "sequenceId": 84,
            "question_text": "unable to stop or control worrying",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "519"
                },
                {
                    "text": "several days",
                    "id": "520"
                },
                {
                    "text": "more than half the days",
                    "id": "521"
                },
                {
                    "text": "nearly every day",
                    "id": "522"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 84,
        "fields": {
            "question_id": "84",
            "sequenceId": 85,
            "question_text": "worrying too much about different things",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "523"
                },
                {
                    "text": "several days",
                    "id": "524"
                },
                {
                    "text": "more than half the days",
                    "id": "525"
                },
                {
                    "text": "nearly every day",
                    "id": "526"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 85,
        "fields": {
            "question_id": "85",
            "sequenceId": 86,
            "question_text": "trouble relaxing",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "527"
                },
                {
                    "text": "several days",
                    "id": "528"
                },
                {
                    "text": "more than half the days",
                    "id": "529"
                },
                {
                    "text": "nearly every day",
                    "id": "530"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 86,
        "fields": {
            "question_id": "86",
            "sequenceId": 87,
            "question_text": "being so restless it's hard to sit still",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "531"
                },
                {
                    "text": "several days",
                    "id": "532"
                },
                {
                    "text": "more than half the days",
                    "id": "533"
                },
                {
                    "text": "nearly every day",
                    "id": "534"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 87,
        "fields": {
            "question_id": "87",
            "sequenceId": 88,
            "question_text": "getting easily annoyed or irritable",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "535"
                },
                {
                    "text": "several days",
                    "id": "536"
                },
                {
                    "text": "more than half the days",
                    "id": "537"
                },
                {
                    "text": "nearly every day",
                    "id": "538"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 88,
        "fields": {
            "question_id": "88",
            "sequenceId": 89,
            "question_text": "feeling afraid like something awful might happen",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "539"
                },
                {
                    "text": "several days",
                    "id": "540"
                },
                {
                    "text": "more than half the days",
                    "id": "541"
                },
                {
                    "text": "nearly every day",
                    "id": "542"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 89,
        "fields": {
            "question_id": "89",
            "sequenceId": 90,
            "question_text": "Which substances have you ever used with some frequency (check all that apply)?",
            "bannerText": "Finally, some details about your substance use and any other habits or concerns.",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "558",
            "otherOptionText": "other",
            "noneId": 560,
            "options": [
                {
                    "text": "smoking tobacco",
                    "id": "543"
                },
                {
                    "text": "smokeless tobacco (chew / snuff)",
                    "id": "544"
                },
                {
                    "text": "vaping",
                    "id": "545"
                },
                {
                    "text": "alcohol",
                    "id": "546"
                },
                {
                    "text": "Rx opiates (pain)",
                    "id": "547"
                },
                {
                    "text": "Rx benzos (anxiety)",
                    "id": "548"
                },
                {
                    "text": "Rx barbiturates (sleep aids)",
                    "id": "549"
                },
                {
                    "text": "Rx ADHD (stimulants)",
                    "id": "550"
                },
                {
                    "text": "marijuana",
                    "id": "551"
                },
                {
                    "text": "cocaine / crack",
                    "id": "552"
                },
                {
                    "text": "heroin",
                    "id": "553"
                },
                {
                    "text": "crystal meth",
                    "id": "554"
                },
                {
                    "text": "ecstasy / MDMA",
                    "id": "555"
                },
                {
                    "text": "hallucinogens",
                    "id": "556"
                },
                {
                    "text": "inhalants (non-medical)",
                    "id": "557"
                },
                {
                    "text": "none",
                    "id": "560"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 90,
        "fields": {
            "question_id": "90",
            "replaceOther":true,
            "sequenceId": 91,
            "question_text": "When did you first and last use each substance?",
            "survey_id": "294219921",
            "question_type": "multiple_input",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noOfSubInputs": 2,
            "options": [
                {
                    "question": "smoking tobacco",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "561"
                        },
                        {
                            "text": "age last used",
                            "id": "562"
                        }
                    ]
                },
                {
                    "question": "smokeless tobacco (chew / snuff)",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "563"
                        },
                        {
                            "text": "age last used",
                            "id": "564"
                        }
                    ]
                },
                {
                    "question": "vaping",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "565"
                        },
                        {
                            "text": "age last used",
                            "id": "566"
                        }
                    ]
                },
                {
                    "question": "alcohol",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "567"
                        },
                        {
                            "text": "age last used",
                            "id": "568"
                        }
                    ]
                },
                {
                    "question": "Rx opiates (pain)",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "569"
                        },
                        {
                            "text": "age last used",
                            "id": "570"
                        }
                    ]
                },
                {
                    "question": "Rx benzos (anxiety)",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "571"
                        },
                        {
                            "text": "age last used",
                            "id": "572"
                        }
                    ]
                },
                {
                    "question": "Rx barbiturates (sleep aids)",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "573"
                        },
                        {
                            "text": "age last used",
                            "id": "574"
                        }
                    ]
                },
                {
                    "question": "Rx ADHD (stimulants)",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "575"
                        },
                        {
                            "text": "age last used",
                            "id": "576"
                        }
                    ]
                },
                {
                    "question": "marijuana",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "577"
                        },
                        {
                            "text": "age last used",
                            "id": "578"
                        }
                    ]
                },
                {
                    "question": "cocaine / crack",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "579"
                        },
                        {
                            "text": "age last used",
                            "id": "580"
                        }
                    ]
                },
                {
                    "question": "heroin",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "581"
                        },
                        {
                            "text": "age last used",
                            "id": "582"
                        }
                    ]
                },
                {
                    "question": "crystal meth",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "583"
                        },
                        {
                            "text": "age last used",
                            "id": "584"
                        }
                    ]
                },
                {
                    "question": "ecstasy / MDMA",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "585"
                        },
                        {
                            "text": "age last used",
                            "id": "586"
                        }
                    ]
                },
                {
                    "question": "hallucinogens",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "587"
                        },
                        {
                            "text": "age last used",
                            "id": "588"
                        }
                    ]
                },
                {
                    "question": "inhalants (non-medical)",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "589"
                        },
                        {
                            "text": "age last used",
                            "id": "590"
                        }
                    ]
                },
                {
                    "question": "Others",
                    "options": [
                        {
                            "text": "age first used",
                            "id": "591"
                        },
                        {
                            "text": "age last used",
                            "id": "592"
                        }
                    ]
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 91,
        "fields": {
            "question_id": "91",
            "sequenceId": 92,
            "question_text": "Which substance(s) were ever a concern?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "609",
            "otherOptionText": "other",
            "noneId": 610,
            "options": [
                {
                    "text": "smoking tobacco",
                    "id": "594"
                },
                {
                    "text": "smokeless tobacco (chew / snuff)",
                    "id": "595"
                },
                {
                    "text": "vaping",
                    "id": "596"
                },
                {
                    "text": "alcohol",
                    "id": "597"
                },
                {
                    "text": "Rx opiates (pain)",
                    "id": "598"
                },
                {
                    "text": "Rx benzos (anxiety)",
                    "id": "599"
                },
                {
                    "text": "Rx barbiturates (sleep aids)",
                    "id": "600"
                },
                {
                    "text": "Rx ADHD (stimulants)",
                    "id": "601"
                },
                {
                    "text": "marijuana",
                    "id": "602"
                },
                {
                    "text": "cocaine / crack",
                    "id": "603"
                },
                {
                    "text": "heroin",
                    "id": "604"
                },
                {
                    "text": "crystal meth",
                    "id": "605"
                },
                {
                    "text": "ecstasy / MDMA",
                    "id": "606"
                },
                {
                    "text": "hallucinogens",
                    "id": "607"
                },
                {
                    "text": "inhalants (non-medical)",
                    "id": "608"
                },
                {
                    "text": "none",
                    "id": "610"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 92,
        "fields": {
            "question_id": "92",
            "replaceOther":true,
            "sequenceId": 93,
            "question_text": "How old were you when each of these first became a concern?",
            "survey_id": "294219921",
            "question_type": "multiple_input",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "626",
            "otherOptionText": "other",
            "noOfSubInputs": 1,
            "options": [
                {
                    "options": [
                        {
                            "text": "smoking tobacco",
                            "id": "611"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "smokeless tobacco (chew / snuff)",
                            "id": "612"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "vaping",
                            "id": "613"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "alcohol",
                            "id": "614"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "Rx opiates (pain)",
                            "id": "615"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "Rx benzes (anxiety)",
                            "id": "616"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "Rx barbiturates (sleep aids)",
                            "id": "617"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "Rx ADHD (stimulants)",
                            "id": "618"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "marijuana",
                            "id": "619"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "cocaine / crack",
                            "id": "620"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "heroin",
                            "id": "621"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "crystal meth",
                            "id": "622"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "ecstasy / MDMA",
                            "id": "623"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "hallucinogens",
                            "id": "624"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "inhalants (non-medical)",
                            "id": "625"
                        }
                    ]
                },
                {
                    "options": [
                        {
                            "text": "Others",
                            "id": "626"
                        }
                    ]
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 93,
        "fields": {
            "question_id": "93",
            "sequenceId": 94,
            "question_text": "How often did you smoke, chew, or vape in the past month (whichever was most often)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "none",
                    "id": "627"
                },
                {
                    "text": "less than weekly",
                    "id": "628"
                },
                {
                    "text": "weekly",
                    "id": "629"
                },
                {
                    "text": "2-3x/week",
                    "id": "630"
                },
                {
                    "text": "daily or most days",
                    "id": "631"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 94,
        "fields": {
            "question_id": "94",
            "sequenceId": 95,
            "question_text": "Has it changed in the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "632"
                },
                {
                    "text": "more often now",
                    "id": "633"
                },
                {
                    "text": "less often now",
                    "id": "634"
                },
                {
                    "text": "not sure",
                    "id": "635"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 95,
        "fields": {
            "question_id": "95",
            "sequenceId": 96,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "none",
                    "id": "636"
                },
                {
                    "text": "less than weekly",
                    "id": "637"
                },
                {
                    "text": "weekly",
                    "id": "638"
                },
                {
                    "text": "2-3x/week",
                    "id": "639"
                },
                {
                    "text": "daily or most days",
                    "id": "640"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 96,
        "fields": {
            "question_id": "96",
            "sequenceId": 97,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "none",
                    "id": "641"
                },
                {
                    "text": "less than weekly",
                    "id": "642"
                },
                {
                    "text": "weekly",
                    "id": "643"
                },
                {
                    "text": "2-3x/week",
                    "id": "644"
                },
                {
                    "text": "daily or most days",
                    "id": "645"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 97,
        "fields": {
            "question_id": "97",
            "sequenceId": 98,
            "question_text": "How much did you smoke, chew, or vape on a typical day in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "1-4 cigs OR 1mg/ml (0.1%)",
                    "id": "646"
                },
                {
                    "text": "5-10 cigs OR 2mg/ml (0.2%)",
                    "id": "647"
                },
                {
                    "text": "11-20 cigs OR 3-4mg/ml (0.3 - 0.4%)",
                    "id": "648"
                },
                {
                    "text": "21-30 cigs OR 5-6mg/ml (0.5 - 0.6%)",
                    "id": "649"
                },
                {
                    "text": "31+ cigs OR 7+mg/ml (0.7-5.0%)",
                    "id": "650"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 98,
        "fields": {
            "question_id": "98",
            "sequenceId": 99,
            "question_text": "Has your daily usage changed in the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same ",
                    "id": "651"
                },
                {
                    "text": "more now ",
                    "id": "652"
                },
                {
                    "text": "less now ",
                    "id": "653"
                },
                {
                    "text": "not sure ",
                    "id": "654"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 99,
        "fields": {
            "question_id": "99",
            "sequenceId": 100,
            "question_text": "How much on a typical day 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "1-4 cigs OR 1mg/ml (0.1%)",
                    "id": "655"
                },
                {
                    "text": "5-10 cigs OR 2mg/ml (0.2%)",
                    "id": "656"
                },
                {
                    "text": "11-20 cigs OR 3-4mg/ml (0.3 - 0.4%)",
                    "id": "657"
                },
                {
                    "text": "21-30 cigs OR 5-6mg/ml (0.5 - 0.6%)",
                    "id": "658"
                },
                {
                    "text": "31+ cigs OR 7+mg/ml (0.7-5.0%)",
                    "id": "659"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 100,
        "fields": {
            "question_id": "100",
            "sequenceId": 101,
            "question_text": "How much on a typical day 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "1-4 cigs OR 1mg/ml (0.1%)",
                    "id": "660"
                },
                {
                    "text": "5-10 cigs OR 2mg/ml (0.2%)",
                    "id": "661"
                },
                {
                    "text": "11-20 cigs OR 3-4mg/ml (0.3 - 0.4%)",
                    "id": "662"
                },
                {
                    "text": "21-30 cigs OR 5-6mg/ml (0.5 - 0.6%)",
                    "id": "663"
                },
                {
                    "text": "31+ cigs OR 7+mg/ml (0.7-5.0%)",
                    "id": "664"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 101,
        "fields": {
            "question_id": "101",
            "sequenceId": 102,
            "question_text": "How soon after waking up did you smoke, chew, or vape in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "within 5 minutes",
                    "id": "665"
                },
                {
                    "text": "6-30 minutes",
                    "id": "666"
                },
                {
                    "text": "31-60 minutes",
                    "id": "667"
                },
                {
                    "text": "after 60 minutes",
                    "id": "668"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 102,
        "fields": {
            "question_id": "102",
            "sequenceId": 103,
            "question_text": "Has this changed in the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "669"
                },
                {
                    "text": "sooner now",
                    "id": "670"
                },
                {
                    "text": "later now",
                    "id": "671"
                },
                {
                    "text": "not sure",
                    "id": "672"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 103,
        "fields": {
            "question_id": "103",
            "sequenceId": 104,
            "question_text": "How soon after waking up 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "within 5 minutes",
                    "id": "673"
                },
                {
                    "text": "6-30 minutes",
                    "id": "674"
                },
                {
                    "text": "31-60 minutes",
                    "id": "675"
                },
                {
                    "text": "after 60 minutes",
                    "id": "676"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 104,
        "fields": {
            "question_id": "104",
            "sequenceId": 105,
            "question_text": "How soon after waking up 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "within 5 minutes",
                    "id": "677"
                },
                {
                    "text": "6-30 minutes",
                    "id": "678"
                },
                {
                    "text": "31-60 minutes",
                    "id": "679"
                },
                {
                    "text": "after 60 minutes",
                    "id": "680"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 105,
        "fields": {
            "question_id": "105",
            "sequenceId": 106,
            "question_text": "In the past month, did you experience any of these using Alcohol (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "692",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "681"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "682"
                },
                {
                    "text": "used too often",
                    "id": "683"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "684"
                },
                {
                    "text": "worried others",
                    "id": "685"
                },
                {
                    "text": "took the place of other interests",
                    "id": "686"
                },
                {
                    "text": "led to risky behavior",
                    "id": "687"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "688"
                },
                {
                    "text": "strong cravings",
                    "id": "689"
                },
                {
                    "text": "hard to stop",
                    "id": "690"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "691"
                },
                {
                    "text": "none of the above",
                    "id": "692"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 106,
        "fields": {
            "question_id": "106",
            "sequenceId": 107,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using Alcohol?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "837"
                },
                {
                    "text": "more symptoms now",
                    "id": "838"
                },
                {
                    "text": "less symptoms now",
                    "id": "839"
                },
                {
                    "text": "not sure",
                    "id": "840"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 107,
        "fields": {
            "question_id": "107",
            "sequenceId": 108,
            "question_text": "1-3 months ago, what did you experience with Alcohol?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "900",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "889"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "890"
                },
                {
                    "text": "used too often",
                    "id": "891"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "892"
                },
                {
                    "text": "worried others",
                    "id": "893"
                },
                {
                    "text": "took the place of other interests",
                    "id": "894"
                },
                {
                    "text": "led to risky behavior",
                    "id": "895"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "896"
                },
                {
                    "text": "strong cravings",
                    "id": "897"
                },
                {
                    "text": "hard to stop",
                    "id": "898"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "899"
                },
                {
                    "text": "none of the above",
                    "id": "900"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 108,
        "fields": {
            "question_id": "108",
            "sequenceId": 109,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "1056",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1045"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1046"
                },
                {
                    "text": "used too often",
                    "id": "1047"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1048"
                },
                {
                    "text": "worried others",
                    "id": "1049"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1050"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1051"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1052"
                },
                {
                    "text": "strong cravings",
                    "id": "1053"
                },
                {
                    "text": "hard to stop",
                    "id": "1054"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1055"
                },
                {
                    "text": "none of the above",
                    "id": "1056"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 109,
        "fields": {
            "question_id": "109",
            "sequenceId": 110,
            "question_text": "How often did you use Alcohol in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1201"
                },
                {
                    "text": "less than weekly",
                    "id": "1202"
                },
                {
                    "text": "weekly",
                    "id": "1203"
                },
                {
                    "text": "2-3x/week",
                    "id": "1204"
                },
                {
                    "text": "daily or most days",
                    "id": "1205"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 110,
        "fields": {
            "question_id": "110",
            "sequenceId": 111,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1266"
                },
                {
                    "text": "more often now",
                    "id": "1267"
                },
                {
                    "text": "less often now",
                    "id": "1268"
                },
                {
                    "text": "not sure",
                    "id": "1269"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 111,
        "fields": {
            "question_id": "111",
            "sequenceId": 112,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1318"
                },
                {
                    "text": "less than weekly",
                    "id": "1319"
                },
                {
                    "text": "weekly",
                    "id": "1320"
                },
                {
                    "text": "2-3x/week",
                    "id": "1321"
                },
                {
                    "text": "daily or most days",
                    "id": "1322"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 112,
        "fields": {
            "question_id": "112",
            "sequenceId": 113,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1383"
                },
                {
                    "text": "less than weekly",
                    "id": "1384"
                },
                {
                    "text": "weekly",
                    "id": "1385"
                },
                {
                    "text": "2-3x/week",
                    "id": "1386"
                },
                {
                    "text": "daily or most days",
                    "id": "1387"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 113,
        "fields": {
            "question_id": "113",
            "sequenceId": 114,
            "question_text": "How often did you have 4 or more alcohol-containing drinks within a few hours in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1448"
                },
                {
                    "text": "less than weekly",
                    "id": "1449"
                },
                {
                    "text": "weekly",
                    "id": "1450"
                },
                {
                    "text": "2-3x/week",
                    "id": "1451"
                },
                {
                    "text": "daily or most days",
                    "id": "1452"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 114,
        "fields": {
            "question_id": "114",
            "sequenceId": 115,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1453"
                },
                {
                    "text": "more often now",
                    "id": "1454"
                },
                {
                    "text": "less often now",
                    "id": "1455"
                },
                {
                    "text": "not sure",
                    "id": "1456"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 115,
        "fields": {
            "question_id": "115",
            "sequenceId": 116,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1457"
                },
                {
                    "text": "monthly or less",
                    "id": "1458"
                },
                {
                    "text": "2-3x/month",
                    "id": "1459"
                },
                {
                    "text": "weekly",
                    "id": "1460"
                },
                {
                    "text": "2-3x/week",
                    "id": "1461"
                },
                {
                    "text": "daily or most days",
                    "id": "1462"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 116,
        "fields": {
            "question_id": "116",
            "sequenceId": 117,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1463"
                },
                {
                    "text": "monthly or less",
                    "id": "1464"
                },
                {
                    "text": "2-3x/month",
                    "id": "1465"
                },
                {
                    "text": "weekly",
                    "id": "1466"
                },
                {
                    "text": "2-3x/week",
                    "id": "1467"
                },
                {
                    "text": "daily or most days",
                    "id": "1468"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 117,
        "fields": {
            "question_id": "145",
            "sequenceId": 118,
            "question_text": "In the past month, did you experience any of these using Rx opiates (pain) (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "704",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "693"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "694"
                },
                {
                    "text": "used too often",
                    "id": "695"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "696"
                },
                {
                    "text": "worried others",
                    "id": "697"
                },
                {
                    "text": "took the place of other interests",
                    "id": "698"
                },
                {
                    "text": "led to risky behavior",
                    "id": "699"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "700"
                },
                {
                    "text": "strong cravings",
                    "id": "701"
                },
                {
                    "text": "hard to stop",
                    "id": "702"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "703"
                },
                {
                    "text": "none of the above",
                    "id": "704"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 118,
        "fields": {
            "question_id": "157",
            "sequenceId": 119,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using Rx opiates (pain)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "841"
                },
                {
                    "text": "more symptoms now",
                    "id": "842"
                },
                {
                    "text": "less symptoms now",
                    "id": "843"
                },
                {
                    "text": "not sure",
                    "id": "844"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 119,
        "fields": {
            "question_id": "169",
            "sequenceId": 120,
            "question_text": "1-3 months ago, what did you experience with Rx opiates (pain)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "912",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "901"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "902"
                },
                {
                    "text": "used too often",
                    "id": "903"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "904"
                },
                {
                    "text": "worried others",
                    "id": "905"
                },
                {
                    "text": "took the place of other interests",
                    "id": "906"
                },
                {
                    "text": "led to risky behavior",
                    "id": "907"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "908"
                },
                {
                    "text": "strong cravings",
                    "id": "909"
                },
                {
                    "text": "hard to stop",
                    "id": "910"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "911"
                },
                {
                    "text": "none of the above",
                    "id": "912"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 120,
        "fields": {
            "question_id": "181",
            "sequenceId": 121,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "1068",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1057"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1058"
                },
                {
                    "text": "used too often",
                    "id": "1059"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1060"
                },
                {
                    "text": "worried others",
                    "id": "1061"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1062"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1063"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1064"
                },
                {
                    "text": "strong cravings",
                    "id": "1065"
                },
                {
                    "text": "hard to stop",
                    "id": "1066"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1067"
                },
                {
                    "text": "none of the above",
                    "id": "1068"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 121,
        "fields": {
            "question_id": "193",
            "sequenceId": 122,
            "question_text": "How often did you use Rx opiates (pain) in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1206"
                },
                {
                    "text": "less than weekly",
                    "id": "1207"
                },
                {
                    "text": "weekly",
                    "id": "1208"
                },
                {
                    "text": "2-3x/week",
                    "id": "1209"
                },
                {
                    "text": "daily or most days",
                    "id": "1210"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 122,
        "fields": {
            "question_id": "205",
            "sequenceId": 123,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1270"
                },
                {
                    "text": "more often now",
                    "id": "1271"
                },
                {
                    "text": "less often now",
                    "id": "1272"
                },
                {
                    "text": "not sure",
                    "id": "1273"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 123,
        "fields": {
            "question_id": "217",
            "sequenceId": 124,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1323"
                },
                {
                    "text": "less than weekly",
                    "id": "1324"
                },
                {
                    "text": "weekly",
                    "id": "1325"
                },
                {
                    "text": "2-3x/week",
                    "id": "1326"
                },
                {
                    "text": "daily or most days",
                    "id": "1327"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 124,
        "fields": {
            "question_id": "229",
            "sequenceId": 125,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1388"
                },
                {
                    "text": "less than weekly",
                    "id": "1389"
                },
                {
                    "text": "weekly",
                    "id": "1390"
                },
                {
                    "text": "2-3x/week",
                    "id": "1391"
                },
                {
                    "text": "daily or most days",
                    "id": "1392"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 125,
        "fields": {
            "question_id": "146",
            "sequenceId": 126,
            "question_text": "In the past month, did you experience any of these using Rx benzos (anxiety) (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "716",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "705"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "706"
                },
                {
                    "text": "used too often",
                    "id": "707"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "708"
                },
                {
                    "text": "worried others",
                    "id": "709"
                },
                {
                    "text": "took the place of other interests",
                    "id": "710"
                },
                {
                    "text": "led to risky behavior",
                    "id": "711"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "712"
                },
                {
                    "text": "strong cravings",
                    "id": "713"
                },
                {
                    "text": "hard to stop",
                    "id": "714"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "715"
                },
                {
                    "text": "none of the above",
                    "id": "716"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 126,
        "fields": {
            "question_id": "158",
            "sequenceId": 127,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using Rx benzos (anxiety)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "845"
                },
                {
                    "text": "more symptoms now",
                    "id": "846"
                },
                {
                    "text": "less symptoms now",
                    "id": "847"
                },
                {
                    "text": "not sure",
                    "id": "848"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 127,
        "fields": {
            "question_id": "170",
            "sequenceId": 128,
            "question_text": "1-3 months ago, what did you experience with Rx benzos (anxiety)?",
            "survey_id": "294219921",
            "noneId": "924",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "913"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "914"
                },
                {
                    "text": "used too often",
                    "id": "915"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "916"
                },
                {
                    "text": "worried others",
                    "id": "917"
                },
                {
                    "text": "took the place of other interests",
                    "id": "918"
                },
                {
                    "text": "led to risky behavior",
                    "id": "919"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "920"
                },
                {
                    "text": "strong cravings",
                    "id": "921"
                },
                {
                    "text": "hard to stop",
                    "id": "922"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "923"
                },
                {
                    "text": "none of the above",
                    "id": "924"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 128,
        "fields": {
            "question_id": "182",
            "sequenceId": 129,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "noneId": "1080",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1069"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1070"
                },
                {
                    "text": "used too often",
                    "id": "1071"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1072"
                },
                {
                    "text": "worried others",
                    "id": "1073"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1074"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1075"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1076"
                },
                {
                    "text": "strong cravings",
                    "id": "1077"
                },
                {
                    "text": "hard to stop",
                    "id": "1078"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1079"
                },
                {
                    "text": "none of the above",
                    "id": "1080"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 129,
        "fields": {
            "question_id": "194",
            "sequenceId": 130,
            "question_text": "How often did you use Rx benzos (anxiety) in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1211"
                },
                {
                    "text": "less than weekly",
                    "id": "1212"
                },
                {
                    "text": "weekly",
                    "id": "1213"
                },
                {
                    "text": "2-3x/week",
                    "id": "1214"
                },
                {
                    "text": "daily or most days",
                    "id": "1215"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 130,
        "fields": {
            "question_id": "206",
            "sequenceId": 131,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1274"
                },
                {
                    "text": "more often now",
                    "id": "1275"
                },
                {
                    "text": "less often now",
                    "id": "1276"
                },
                {
                    "text": "not sure",
                    "id": "1277"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 131,
        "fields": {
            "question_id": "218",
            "sequenceId": 132,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1328"
                },
                {
                    "text": "less than weekly",
                    "id": "1329"
                },
                {
                    "text": "weekly",
                    "id": "1330"
                },
                {
                    "text": "2-3x/week",
                    "id": "1331"
                },
                {
                    "text": "daily or most days",
                    "id": "1332"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 132,
        "fields": {
            "question_id": "230",
            "sequenceId": 133,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1393"
                },
                {
                    "text": "less than weekly",
                    "id": "1394"
                },
                {
                    "text": "weekly",
                    "id": "1395"
                },
                {
                    "text": "2-3x/week",
                    "id": "1396"
                },
                {
                    "text": "daily or most days",
                    "id": "1397"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 133,
        "fields": {
            "question_id": "147",
            "sequenceId": 134,
            "question_text": "In the past month, did you experience any of these using Rx barbiturates (sleep aids) (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noneId": "728",
            "options": [
                {
                    "text": "used too much",
                    "id": "717"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "718"
                },
                {
                    "text": "used too often",
                    "id": "719"
                },
                {
                    "text": "got in the way of home, family, job, or school",
                    "id": "720"
                },
                {
                    "text": "worried others",
                    "id": "721"
                },
                {
                    "text": "took the place of other interests",
                    "id": "722"
                },
                {
                    "text": "led to risky behavior",
                    "id": "723"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "724"
                },
                {
                    "text": "strong cravings",
                    "id": "725"
                },
                {
                    "text": "hard to stop",
                    "id": "726"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "727"
                },
                {
                    "text": "none of the above",
                    "id": "728"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 134,
        "fields": {
            "question_id": "159",
            "sequenceId": 135,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using Rx barbiturates (sleep aids)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "849"
                },
                {
                    "text": "more symptoms now",
                    "id": "850"
                },
                {
                    "text": "less symptoms now",
                    "id": "851"
                },
                {
                    "text": "not sure",
                    "id": "852"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 135,
        "fields": {
            "question_id": "171",
            "sequenceId": 136,
            "question_text": "1-3 months ago, what did you experience with Rx barbiturates (sleep aids)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noneId": "936",
            "options": [
                {
                    "text": "used too much",
                    "id": "925"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "926"
                },
                {
                    "text": "used too often",
                    "id": "927"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "928"
                },
                {
                    "text": "worried others",
                    "id": "929"
                },
                {
                    "text": "took the place of other interests",
                    "id": "930"
                },
                {
                    "text": "led to risky behavior",
                    "id": "931"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "932"
                },
                {
                    "text": "strong cravings",
                    "id": "933"
                },
                {
                    "text": "hard to stop",
                    "id": "934"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "935"
                },
                {
                    "text": "none of the above",
                    "id": "936"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 136,
        "fields": {
            "question_id": "183",
            "sequenceId": 137,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "1092",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1081"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1082"
                },
                {
                    "text": "used too often",
                    "id": "1083"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1084"
                },
                {
                    "text": "worried others",
                    "id": "1085"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1086"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1087"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1088"
                },
                {
                    "text": "strong cravings",
                    "id": "1089"
                },
                {
                    "text": "hard to stop",
                    "id": "1090"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1091"
                },
                {
                    "text": "none of the above",
                    "id": "1092"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 137,
        "fields": {
            "question_id": "195",
            "sequenceId": 138,
            "question_text": "How often did you use Rx barbiturates (sleep aids) in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1216"
                },
                {
                    "text": "less than weekly",
                    "id": "1217"
                },
                {
                    "text": "weekly",
                    "id": "1218"
                },
                {
                    "text": "2-3x/week",
                    "id": "1219"
                },
                {
                    "text": "daily or most days",
                    "id": "1220"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 138,
        "fields": {
            "question_id": "207",
            "sequenceId": 139,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1278"
                },
                {
                    "text": "more often now",
                    "id": "1279"
                },
                {
                    "text": "less often now",
                    "id": "1280"
                },
                {
                    "text": "not sure",
                    "id": "1281"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 139,
        "fields": {
            "question_id": "219",
            "sequenceId": 140,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1333"
                },
                {
                    "text": "less than weekly",
                    "id": "1334"
                },
                {
                    "text": "weekly",
                    "id": "1335"
                },
                {
                    "text": "2-3x/week",
                    "id": "1336"
                },
                {
                    "text": "daily or most days",
                    "id": "1337"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 140,
        "fields": {
            "question_id": "231",
            "sequenceId": 141,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1398"
                },
                {
                    "text": "less than weekly",
                    "id": "1399"
                },
                {
                    "text": "weekly",
                    "id": "1400"
                },
                {
                    "text": "2-3x/week",
                    "id": "1401"
                },
                {
                    "text": "daily or most days",
                    "id": "1402"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 141,
        "fields": {
            "question_id": "148",
            "sequenceId": 142,
            "question_text": "In the past month, did you experience any of these using Rx ADHD (stimulants) (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "740",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "729"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "730"
                },
                {
                    "text": "used too often",
                    "id": "731"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "732"
                },
                {
                    "text": "worried others",
                    "id": "733"
                },
                {
                    "text": "took the place of other interests",
                    "id": "734"
                },
                {
                    "text": "led to risky behavior",
                    "id": "735"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "736"
                },
                {
                    "text": "strong cravings",
                    "id": "737"
                },
                {
                    "text": "hard to stop",
                    "id": "738"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "739"
                },
                {
                    "text": "none of the above",
                    "id": "740"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 142,
        "fields": {
            "question_id": "160",
            "sequenceId": 143,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using Rx ADHD (stimulants)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "853"
                },
                {
                    "text": "more symptoms now",
                    "id": "854"
                },
                {
                    "text": "less symptoms now",
                    "id": "855"
                },
                {
                    "text": "not sure",
                    "id": "856"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 143,
        "fields": {
            "question_id": "172",
            "sequenceId": 144,
            "question_text": "1-3 months ago, what did you experience with Rx ADHD (stimulants)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "948",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "937"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "938"
                },
                {
                    "text": "used too often",
                    "id": "939"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "940"
                },
                {
                    "text": "worried others",
                    "id": "941"
                },
                {
                    "text": "took the place of other interests",
                    "id": "942"
                },
                {
                    "text": "led to risky behavior",
                    "id": "943"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "944"
                },
                {
                    "text": "strong cravings",
                    "id": "945"
                },
                {
                    "text": "hard to stop",
                    "id": "946"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "947"
                },
                {
                    "text": "none of the above",
                    "id": "948"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 144,
        "fields": {
            "question_id": "184",
            "sequenceId": 145,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "1104",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1093"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1094"
                },
                {
                    "text": "used too often",
                    "id": "1095"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1096"
                },
                {
                    "text": "worried others",
                    "id": "1097"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1098"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1099"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1100"
                },
                {
                    "text": "strong cravings",
                    "id": "1101"
                },
                {
                    "text": "hard to stop",
                    "id": "1102"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1103"
                },
                {
                    "text": "none of the above",
                    "id": "1104"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 145,
        "fields": {
            "question_id": "196",
            "sequenceId": 146,
            "question_text": "How often did you use Rx ADHD (stimulants) in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1221"
                },
                {
                    "text": "less than weekly",
                    "id": "1222"
                },
                {
                    "text": "weekly",
                    "id": "1223"
                },
                {
                    "text": "2-3x/week",
                    "id": "1224"
                },
                {
                    "text": "daily or most days",
                    "id": "1225"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 146,
        "fields": {
            "question_id": "208",
            "sequenceId": 147,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1282"
                },
                {
                    "text": "more often now",
                    "id": "1283"
                },
                {
                    "text": "less often now",
                    "id": "1284"
                },
                {
                    "text": "not sure",
                    "id": "1285"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 147,
        "fields": {
            "question_id": "220",
            "sequenceId": 148,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1338"
                },
                {
                    "text": "less than weekly",
                    "id": "1339"
                },
                {
                    "text": "weekly",
                    "id": "1340"
                },
                {
                    "text": "2-3x/week",
                    "id": "1341"
                },
                {
                    "text": "daily or most days",
                    "id": "1342"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 148,
        "fields": {
            "question_id": "232",
            "sequenceId": 149,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1403"
                },
                {
                    "text": "less than weekly",
                    "id": "1404"
                },
                {
                    "text": "weekly",
                    "id": "1405"
                },
                {
                    "text": "2-3x/week",
                    "id": "1406"
                },
                {
                    "text": "daily or most days",
                    "id": "1407"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 149,
        "fields": {
            "question_id": "149",
            "sequenceId": 150,
            "question_text": "In the past month, did you experience any of these using marijuana (check all that apply)?",
            "survey_id": "294219921",
            "noneId": "752",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "741"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "742"
                },
                {
                    "text": "used too often",
                    "id": "743"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "744"
                },
                {
                    "text": "worried others",
                    "id": "745"
                },
                {
                    "text": "took the place of other interests",
                    "id": "746"
                },
                {
                    "text": "led to risky behavior",
                    "id": "747"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "748"
                },
                {
                    "text": "strong cravings",
                    "id": "749"
                },
                {
                    "text": "hard to stop",
                    "id": "750"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "751"
                },
                {
                    "text": "none of the above",
                    "id": "752"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 150,
        "fields": {
            "question_id": "161",
            "sequenceId": 151,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using marijuana?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "857"
                },
                {
                    "text": "more symptoms now",
                    "id": "858"
                },
                {
                    "text": "less symptoms now",
                    "id": "859"
                },
                {
                    "text": "not sure",
                    "id": "860"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 151,
        "fields": {
            "question_id": "173",
            "sequenceId": 152,
            "question_text": "1-3 months ago, what did you experience with marijuana?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "960",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "949"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "950"
                },
                {
                    "text": "used too often",
                    "id": "951"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "952"
                },
                {
                    "text": "worried others",
                    "id": "953"
                },
                {
                    "text": "took the place of other interests",
                    "id": "954"
                },
                {
                    "text": "led to risky behavior",
                    "id": "955"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "956"
                },
                {
                    "text": "strong cravings",
                    "id": "957"
                },
                {
                    "text": "hard to stop",
                    "id": "958"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "959"
                },
                {
                    "text": "none of the above",
                    "id": "960"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 152,
        "fields": {
            "question_id": "185",
            "sequenceId": 153,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "1116",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1105"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1106"
                },
                {
                    "text": "used too often",
                    "id": "1107"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1108"
                },
                {
                    "text": "worried others",
                    "id": "1109"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1110"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1111"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1112"
                },
                {
                    "text": "strong cravings",
                    "id": "1113"
                },
                {
                    "text": "hard to stop",
                    "id": "1114"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1115"
                },
                {
                    "text": "none of the above",
                    "id": "1116"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 153,
        "fields": {
            "question_id": "197",
            "sequenceId": 154,
            "question_text": "How often did you use marijuana in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1226"
                },
                {
                    "text": "less than weekly",
                    "id": "1227"
                },
                {
                    "text": "weekly",
                    "id": "1228"
                },
                {
                    "text": "2-3x/week",
                    "id": "1229"
                },
                {
                    "text": "daily or most days",
                    "id": "1230"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 154,
        "fields": {
            "question_id": "209",
            "sequenceId": 155,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1286"
                },
                {
                    "text": "more often now",
                    "id": "1287"
                },
                {
                    "text": "less often now",
                    "id": "1288"
                },
                {
                    "text": "not sure",
                    "id": "1289"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 155,
        "fields": {
            "question_id": "221",
            "sequenceId": 156,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1343"
                },
                {
                    "text": "less than weekly",
                    "id": "1344"
                },
                {
                    "text": "weekly",
                    "id": "1345"
                },
                {
                    "text": "2-3x/week",
                    "id": "1346"
                },
                {
                    "text": "daily or most days",
                    "id": "1347"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 156,
        "fields": {
            "question_id": "233",
            "sequenceId": 157,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1408"
                },
                {
                    "text": "less than weekly",
                    "id": "1409"
                },
                {
                    "text": "weekly",
                    "id": "1410"
                },
                {
                    "text": "2-3x/week",
                    "id": "1411"
                },
                {
                    "text": "daily or most days",
                    "id": "1412"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 157,
        "fields": {
            "question_id": "150",
            "sequenceId": 158,
            "question_text": "In the past month, did you experience any of these using cocaine / crack (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "764",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "753"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "754"
                },
                {
                    "text": "used too often",
                    "id": "755"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "756"
                },
                {
                    "text": "worried others",
                    "id": "757"
                },
                {
                    "text": "took the place of other interests",
                    "id": "758"
                },
                {
                    "text": "led to risky behavior",
                    "id": "759"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "760"
                },
                {
                    "text": "strong cravings",
                    "id": "761"
                },
                {
                    "text": "hard to stop",
                    "id": "762"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "763"
                },
                {
                    "text": "none of the above",
                    "id": "764"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 158,
        "fields": {
            "question_id": "162",
            "sequenceId": 159,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using cocaine / crack?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "861"
                },
                {
                    "text": "more symptoms now",
                    "id": "862"
                },
                {
                    "text": "less symptoms now",
                    "id": "863"
                },
                {
                    "text": "not sure",
                    "id": "864"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 159,
        "fields": {
            "question_id": "174",
            "sequenceId": 160,
            "question_text": "1-3 months ago, what did you experience with cocaine / crack?",
            "survey_id": "294219921",
            "noneId": "972",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "961"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "962"
                },
                {
                    "text": "used too often",
                    "id": "963"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "964"
                },
                {
                    "text": "worried others",
                    "id": "965"
                },
                {
                    "text": "took the place of other interests",
                    "id": "966"
                },
                {
                    "text": "led to risky behavior",
                    "id": "967"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "968"
                },
                {
                    "text": "strong cravings",
                    "id": "969"
                },
                {
                    "text": "hard to stop",
                    "id": "970"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "971"
                },
                {
                    "text": "none of the above",
                    "id": "972"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 160,
        "fields": {
            "question_id": "186",
            "sequenceId": 161,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "1128",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1117"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1118"
                },
                {
                    "text": "used too often",
                    "id": "1119"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1120"
                },
                {
                    "text": "worried others",
                    "id": "1121"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1122"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1123"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1124"
                },
                {
                    "text": "strong cravings",
                    "id": "1125"
                },
                {
                    "text": "hard to stop",
                    "id": "1126"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1127"
                },
                {
                    "text": "none of the above",
                    "id": "1128"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 161,
        "fields": {
            "question_id": "198",
            "sequenceId": 162,
            "question_text": "How often did you use cocaine / crack in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1231"
                },
                {
                    "text": "less than weekly",
                    "id": "1232"
                },
                {
                    "text": "weekly",
                    "id": "1233"
                },
                {
                    "text": "2-3x/week",
                    "id": "1234"
                },
                {
                    "text": "daily or most days",
                    "id": "1235"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 162,
        "fields": {
            "question_id": "210",
            "sequenceId": 163,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1290"
                },
                {
                    "text": "more often now",
                    "id": "1291"
                },
                {
                    "text": "less often now",
                    "id": "1292"
                },
                {
                    "text": "not sure",
                    "id": "1293"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 163,
        "fields": {
            "question_id": "222",
            "sequenceId": 164,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1348"
                },
                {
                    "text": "less than weekly",
                    "id": "1349"
                },
                {
                    "text": "weekly",
                    "id": "1350"
                },
                {
                    "text": "2-3x/week",
                    "id": "1351"
                },
                {
                    "text": "daily or most days",
                    "id": "1352"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 164,
        "fields": {
            "question_id": "234",
            "sequenceId": 165,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1413"
                },
                {
                    "text": "less than weekly",
                    "id": "1414"
                },
                {
                    "text": "weekly",
                    "id": "1415"
                },
                {
                    "text": "2-3x/week",
                    "id": "1416"
                },
                {
                    "text": "daily or most days",
                    "id": "1417"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 165,
        "fields": {
            "question_id": "151",
            "sequenceId": 166,
            "question_text": "In the past month, did you experience any of these using heroin (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "776",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "765"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "766"
                },
                {
                    "text": "used too often",
                    "id": "767"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "768"
                },
                {
                    "text": "worried others",
                    "id": "769"
                },
                {
                    "text": "took the place of other interests",
                    "id": "770"
                },
                {
                    "text": "led to risky behavior",
                    "id": "771"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "772"
                },
                {
                    "text": "strong cravings",
                    "id": "773"
                },
                {
                    "text": "hard to stop",
                    "id": "774"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "775"
                },
                {
                    "text": "none of the above",
                    "id": "776"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 166,
        "fields": {
            "question_id": "163",
            "sequenceId": 167,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using heroin?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "865"
                },
                {
                    "text": "more symptoms now",
                    "id": "866"
                },
                {
                    "text": "less symptoms now",
                    "id": "867"
                },
                {
                    "text": "not sure",
                    "id": "868"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 167,
        "fields": {
            "question_id": "175",
            "sequenceId": 168,
            "question_text": "1-3 months ago, what did you experience with heroin?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "984",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "973"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "974"
                },
                {
                    "text": "used too often",
                    "id": "975"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "976"
                },
                {
                    "text": "worried others",
                    "id": "977"
                },
                {
                    "text": "took the place of other interests",
                    "id": "978"
                },
                {
                    "text": "led to risky behavior",
                    "id": "979"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "980"
                },
                {
                    "text": "strong cravings",
                    "id": "981"
                },
                {
                    "text": "hard to stop",
                    "id": "982"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "983"
                },
                {
                    "text": "none of the above",
                    "id": "984"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 168,
        "fields": {
            "question_id": "187",
            "sequenceId": 169,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "1140",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1129"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1130"
                },
                {
                    "text": "used too often",
                    "id": "1131"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1132"
                },
                {
                    "text": "worried others",
                    "id": "1133"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1134"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1135"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1136"
                },
                {
                    "text": "strong cravings",
                    "id": "1137"
                },
                {
                    "text": "hard to stop",
                    "id": "1138"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1139"
                },
                {
                    "text": "none of the above",
                    "id": "1140"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 169,
        "fields": {
            "question_id": "199",
            "sequenceId": 170,
            "question_text": "How often did you use heroin in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1236"
                },
                {
                    "text": "less than weekly",
                    "id": "1237"
                },
                {
                    "text": "weekly",
                    "id": "1238"
                },
                {
                    "text": "2-3x/week",
                    "id": "1239"
                },
                {
                    "text": "daily or most days",
                    "id": "1240"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 170,
        "fields": {
            "question_id": "211",
            "sequenceId": 171,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1294"
                },
                {
                    "text": "more often now",
                    "id": "1295"
                },
                {
                    "text": "less often now",
                    "id": "1296"
                },
                {
                    "text": "not sure",
                    "id": "1297"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 171,
        "fields": {
            "question_id": "223",
            "sequenceId": 172,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1353"
                },
                {
                    "text": "less than weekly",
                    "id": "1354"
                },
                {
                    "text": "weekly",
                    "id": "1355"
                },
                {
                    "text": "2-3x/week",
                    "id": "1356"
                },
                {
                    "text": "daily or most days",
                    "id": "1357"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 172,
        "fields": {
            "question_id": "235",
            "sequenceId": 173,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1418"
                },
                {
                    "text": "less than weekly",
                    "id": "1419"
                },
                {
                    "text": "weekly",
                    "id": "1420"
                },
                {
                    "text": "2-3x/week",
                    "id": "1421"
                },
                {
                    "text": "daily or most days",
                    "id": "1422"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 173,
        "fields": {
            "question_id": "152",
            "sequenceId": 174,
            "question_text": "In the past month, did you experience any of these using crystal meth (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "788",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "777"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "778"
                },
                {
                    "text": "used too often",
                    "id": "779"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "780"
                },
                {
                    "text": "worried others",
                    "id": "781"
                },
                {
                    "text": "took the place of other interests",
                    "id": "782"
                },
                {
                    "text": "led to risky behavior",
                    "id": "783"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "784"
                },
                {
                    "text": "strong cravings",
                    "id": "785"
                },
                {
                    "text": "hard to stop",
                    "id": "786"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "787"
                },
                {
                    "text": "none of the above",
                    "id": "788"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 174,
        "fields": {
            "question_id": "164",
            "sequenceId": 175,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using crystal meth?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "869"
                },
                {
                    "text": "more symptoms now",
                    "id": "870"
                },
                {
                    "text": "less symptoms now",
                    "id": "871"
                },
                {
                    "text": "not sure",
                    "id": "872"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 175,
        "fields": {
            "question_id": "176",
            "sequenceId": 176,
            "question_text": "1-3 months ago, what did you experience with crystal meth?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "996",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "985"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "986"
                },
                {
                    "text": "used too often",
                    "id": "987"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "988"
                },
                {
                    "text": "worried others",
                    "id": "989"
                },
                {
                    "text": "took the place of other interests",
                    "id": "990"
                },
                {
                    "text": "led to risky behavior",
                    "id": "991"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "992"
                },
                {
                    "text": "strong cravings",
                    "id": "993"
                },
                {
                    "text": "hard to stop",
                    "id": "994"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "995"
                },
                {
                    "text": "none of the above",
                    "id": "996"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 176,
        "fields": {
            "question_id": "188",
            "sequenceId": 177,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "1152",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1141"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1142"
                },
                {
                    "text": "used too often",
                    "id": "1143"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1144"
                },
                {
                    "text": "worried others",
                    "id": "1145"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1146"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1147"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1148"
                },
                {
                    "text": "strong cravings",
                    "id": "1149"
                },
                {
                    "text": "hard to stop",
                    "id": "1150"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1151"
                },
                {
                    "text": "none of the above",
                    "id": "1152"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 177,
        "fields": {
            "question_id": "200",
            "sequenceId": 178,
            "question_text": "How often did you use crystal meth in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1241"
                },
                {
                    "text": "less than weekly",
                    "id": "1242"
                },
                {
                    "text": "weekly",
                    "id": "1243"
                },
                {
                    "text": "2-3x/week",
                    "id": "1244"
                },
                {
                    "text": "daily or most days",
                    "id": "1245"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 178,
        "fields": {
            "question_id": "212",
            "sequenceId": 179,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1298"
                },
                {
                    "text": "more often now",
                    "id": "1299"
                },
                {
                    "text": "less often now",
                    "id": "1300"
                },
                {
                    "text": "not sure",
                    "id": "1301"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 179,
        "fields": {
            "question_id": "224",
            "sequenceId": 180,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1358"
                },
                {
                    "text": "less than weekly",
                    "id": "1359"
                },
                {
                    "text": "weekly",
                    "id": "1360"
                },
                {
                    "text": "2-3x/week",
                    "id": "1361"
                },
                {
                    "text": "daily or most days",
                    "id": "1362"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 180,
        "fields": {
            "question_id": "236",
            "sequenceId": 181,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1423"
                },
                {
                    "text": "less than weekly",
                    "id": "1424"
                },
                {
                    "text": "weekly",
                    "id": "1425"
                },
                {
                    "text": "2-3x/week",
                    "id": "1426"
                },
                {
                    "text": "daily or most days",
                    "id": "1427"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 181,
        "fields": {
            "question_id": "153",
            "sequenceId": 182,
            "question_text": "In the past month, did you experience any of these using ecstasy / MDMA (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "800",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "789"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "790"
                },
                {
                    "text": "used too often",
                    "id": "791"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "792"
                },
                {
                    "text": "worried others",
                    "id": "793"
                },
                {
                    "text": "took the place of other interests",
                    "id": "794"
                },
                {
                    "text": "led to risky behavior",
                    "id": "795"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "796"
                },
                {
                    "text": "strong cravings",
                    "id": "797"
                },
                {
                    "text": "hard to stop",
                    "id": "798"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "799"
                },
                {
                    "text": "none of the above",
                    "id": "800"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 182,
        "fields": {
            "question_id": "165",
            "sequenceId": 183,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using ecstasy / MDMA?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "873"
                },
                {
                    "text": "more symptoms now",
                    "id": "874"
                },
                {
                    "text": "less symptoms now",
                    "id": "875"
                },
                {
                    "text": "not sure",
                    "id": "876"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 183,
        "fields": {
            "question_id": "177",
            "sequenceId": 184,
            "question_text": "1-3 months ago, what did you experience with ecstasy / MDMA?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "1008",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "997"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "998"
                },
                {
                    "text": "used too often",
                    "id": "999"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1000"
                },
                {
                    "text": "worried others",
                    "id": "1001"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1002"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1003"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1004"
                },
                {
                    "text": "strong cravings",
                    "id": "1005"
                },
                {
                    "text": "hard to stop",
                    "id": "1006"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1007"
                },
                {
                    "text": "none of the above",
                    "id": "1008"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 184,
        "fields": {
            "question_id": "189",
            "sequenceId": 185,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "noneId": "1164",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1153"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1154"
                },
                {
                    "text": "used too often",
                    "id": "1155"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1156"
                },
                {
                    "text": "worried others",
                    "id": "1157"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1158"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1159"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1160"
                },
                {
                    "text": "strong cravings",
                    "id": "1161"
                },
                {
                    "text": "hard to stop",
                    "id": "1162"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1163"
                },
                {
                    "text": "none of the above",
                    "id": "1164"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 185,
        "fields": {
            "question_id": "201",
            "sequenceId": 186,
            "question_text": "How often did you use ecstasy / MDMA in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1246"
                },
                {
                    "text": "less than weekly",
                    "id": "1247"
                },
                {
                    "text": "weekly",
                    "id": "1248"
                },
                {
                    "text": "2-3x/week",
                    "id": "1249"
                },
                {
                    "text": "daily or most days",
                    "id": "1250"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 186,
        "fields": {
            "question_id": "213",
            "sequenceId": 187,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1302"
                },
                {
                    "text": "more often now",
                    "id": "1303"
                },
                {
                    "text": "less often now",
                    "id": "1304"
                },
                {
                    "text": "not sure",
                    "id": "1305"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 187,
        "fields": {
            "question_id": "225",
            "sequenceId": 188,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1363"
                },
                {
                    "text": "less than weekly",
                    "id": "1364"
                },
                {
                    "text": "weekly",
                    "id": "1365"
                },
                {
                    "text": "2-3x/week",
                    "id": "1366"
                },
                {
                    "text": "daily or most days",
                    "id": "1367"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 188,
        "fields": {
            "question_id": "237",
            "sequenceId": 189,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1428"
                },
                {
                    "text": "less than weekly",
                    "id": "1429"
                },
                {
                    "text": "weekly",
                    "id": "1430"
                },
                {
                    "text": "2-3x/week",
                    "id": "1431"
                },
                {
                    "text": "daily or most days",
                    "id": "1432"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 189,
        "fields": {
            "question_id": "154",
            "sequenceId": 190,
            "question_text": "In the past month, did you experience any of these using hallucinogens (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "812",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "801"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "802"
                },
                {
                    "text": "used too often",
                    "id": "803"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "804"
                },
                {
                    "text": "worried others",
                    "id": "805"
                },
                {
                    "text": "took the place of other interests",
                    "id": "806"
                },
                {
                    "text": "led to risky behavior",
                    "id": "807"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "808"
                },
                {
                    "text": "strong cravings",
                    "id": "809"
                },
                {
                    "text": "hard to stop",
                    "id": "810"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "811"
                },
                {
                    "text": "none of the above",
                    "id": "812"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 190,
        "fields": {
            "question_id": "166",
            "sequenceId": 191,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using hallucinogens?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "877"
                },
                {
                    "text": "more symptoms now",
                    "id": "878"
                },
                {
                    "text": "less symptoms now",
                    "id": "879"
                },
                {
                    "text": "not sure",
                    "id": "880"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 191,
        "fields": {
            "question_id": "178",
            "sequenceId": 192,
            "question_text": "1-3 months ago, what did you experience with hallucinogens?",
            "survey_id": "294219921",
            "noneId": "1020",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1009"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1010"
                },
                {
                    "text": "used too often",
                    "id": "1011"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1012"
                },
                {
                    "text": "worried others",
                    "id": "1013"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1014"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1015"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1016"
                },
                {
                    "text": "strong cravings",
                    "id": "1017"
                },
                {
                    "text": "hard to stop",
                    "id": "1018"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1019"
                },
                {
                    "text": "none of the above",
                    "id": "1020"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 192,
        "fields": {
            "question_id": "190",
            "sequenceId": 193,
            "question_text": "How about 4-12 months ago?",
            "noneId": "1176",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1165"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1166"
                },
                {
                    "text": "used too often",
                    "id": "1167"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1168"
                },
                {
                    "text": "worried others",
                    "id": "1169"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1170"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1171"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1172"
                },
                {
                    "text": "strong cravings",
                    "id": "1173"
                },
                {
                    "text": "hard to stop",
                    "id": "1174"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1175"
                },
                {
                    "text": "none of the above",
                    "id": "1176"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 193,
        "fields": {
            "question_id": "202",
            "sequenceId": 194,
            "question_text": "How often did you use hallucinogens in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1251"
                },
                {
                    "text": "less than weekly",
                    "id": "1252"
                },
                {
                    "text": "weekly",
                    "id": "1253"
                },
                {
                    "text": "2-3x/week",
                    "id": "1254"
                },
                {
                    "text": "daily or most days",
                    "id": "1255"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 194,
        "fields": {
            "question_id": "214",
            "sequenceId": 195,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1306"
                },
                {
                    "text": "more often now",
                    "id": "1307"
                },
                {
                    "text": "less often now",
                    "id": "1308"
                },
                {
                    "text": "not sure",
                    "id": "1309"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 195,
        "fields": {
            "question_id": "226",
            "sequenceId": 196,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1368"
                },
                {
                    "text": "less than weekly",
                    "id": "1369"
                },
                {
                    "text": "weekly",
                    "id": "1370"
                },
                {
                    "text": "2-3x/week",
                    "id": "1371"
                },
                {
                    "text": "daily or most days",
                    "id": "1372"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 196,
        "fields": {
            "question_id": "238",
            "sequenceId": 197,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1433"
                },
                {
                    "text": "less than weekly",
                    "id": "1434"
                },
                {
                    "text": "weekly",
                    "id": "1435"
                },
                {
                    "text": "2-3x/week",
                    "id": "1436"
                },
                {
                    "text": "daily or most days",
                    "id": "1437"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 197,
        "fields": {
            "question_id": "155",
            "sequenceId": 198,
            "question_text": "In the past month, did you experience any of these using inhalants (non-medical) (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "824",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "813"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "814"
                },
                {
                    "text": "used too often",
                    "id": "815"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "816"
                },
                {
                    "text": "worried others",
                    "id": "817"
                },
                {
                    "text": "took the place of other interests",
                    "id": "818"
                },
                {
                    "text": "led to risky behavior",
                    "id": "819"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "820"
                },
                {
                    "text": "strong cravings",
                    "id": "821"
                },
                {
                    "text": "hard to stop",
                    "id": "822"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "823"
                },
                {
                    "text": "none of the above",
                    "id": "824"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 198,
        "fields": {
            "question_id": "167",
            "sequenceId": 199,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using inhalants (non-medical)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "881"
                },
                {
                    "text": "more symptoms now",
                    "id": "882"
                },
                {
                    "text": "less symptoms now",
                    "id": "883"
                },
                {
                    "text": "not sure",
                    "id": "884"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 199,
        "fields": {
            "question_id": "179",
            "sequenceId": 200,
            "question_text": "1-3 months ago, what did you experience with inhalants (non-medical)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "1032",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1021"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1022"
                },
                {
                    "text": "used too often",
                    "id": "1023"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1024"
                },
                {
                    "text": "worried others",
                    "id": "1025"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1026"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1027"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1028"
                },
                {
                    "text": "strong cravings",
                    "id": "1029"
                },
                {
                    "text": "hard to stop",
                    "id": "1030"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1031"
                },
                {
                    "text": "none of the above",
                    "id": "1032"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 200,
        "fields": {
            "question_id": "191",
            "sequenceId": 201,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "noneId": "1188",
            "options": [
                {
                    "text": "used too much",
                    "id": "1177"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1178"
                },
                {
                    "text": "used too often",
                    "id": "1179"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1180"
                },
                {
                    "text": "worried others",
                    "id": "1181"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1182"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1183"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1184"
                },
                {
                    "text": "strong cravings",
                    "id": "1185"
                },
                {
                    "text": "hard to stop",
                    "id": "1186"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1187"
                },
                {
                    "text": "none of the above",
                    "id": "1188"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 201,
        "fields": {
            "question_id": "203",
            "sequenceId": 202,
            "question_text": "How often did you use inhalants (non-medical) in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1256"
                },
                {
                    "text": "less than weekly",
                    "id": "1257"
                },
                {
                    "text": "weekly",
                    "id": "1258"
                },
                {
                    "text": "2-3x/week",
                    "id": "1259"
                },
                {
                    "text": "daily or most days",
                    "id": "1260"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 202,
        "fields": {
            "question_id": "215",
            "sequenceId": 203,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1310"
                },
                {
                    "text": "more often now",
                    "id": "1311"
                },
                {
                    "text": "less often now",
                    "id": "1312"
                },
                {
                    "text": "not sure",
                    "id": "1313"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 203,
        "fields": {
            "question_id": "227",
            "sequenceId": 204,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1373"
                },
                {
                    "text": "less than weekly",
                    "id": "1374"
                },
                {
                    "text": "weekly",
                    "id": "1375"
                },
                {
                    "text": "2-3x/week",
                    "id": "1376"
                },
                {
                    "text": "daily or most days",
                    "id": "1377"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 204,
        "fields": {
            "question_id": "239",
            "sequenceId": 205,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1438"
                },
                {
                    "text": "less than weekly",
                    "id": "1439"
                },
                {
                    "text": "weekly",
                    "id": "1440"
                },
                {
                    "text": "2-3x/week",
                    "id": "1441"
                },
                {
                    "text": "daily or most days",
                    "id": "1442"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 205,
        "fields": {
            "question_id": "156",
            "sequenceId": 206,
            "question_text": "In the past month, did you experience any of these using others (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "noneId": "836",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "825"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "826"
                },
                {
                    "text": "used too often",
                    "id": "827"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "828"
                },
                {
                    "text": "worried others",
                    "id": "829"
                },
                {
                    "text": "took the place of other interests",
                    "id": "830"
                },
                {
                    "text": "led to risky behavior",
                    "id": "831"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "832"
                },
                {
                    "text": "strong cravings",
                    "id": "833"
                },
                {
                    "text": "hard to stop",
                    "id": "834"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "835"
                },
                {
                    "text": "none of the above",
                    "id": "836"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 206,
        "fields": {
            "question_id": "168",
            "sequenceId": 207,
            "question_text": "Do you have more of these symptoms now vs the past 12 months using others?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "885"
                },
                {
                    "text": "more symptoms now",
                    "id": "886"
                },
                {
                    "text": "less symptoms now",
                    "id": "887"
                },
                {
                    "text": "not sure",
                    "id": "888"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 207,
        "fields": {
            "question_id": "180",
            "sequenceId": 208,
            "question_text": "1-3 months ago, what did you experience with others?",
            "survey_id": "294219921",
            "noneId": "1044",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1033"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1034"
                },
                {
                    "text": "used too often",
                    "id": "1035"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1036"
                },
                {
                    "text": "worried others",
                    "id": "1037"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1038"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1039"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1040"
                },
                {
                    "text": "strong cravings",
                    "id": "1041"
                },
                {
                    "text": "hard to stop",
                    "id": "1042"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1043"
                },
                {
                    "text": "none of the above",
                    "id": "1044"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 208,
        "fields": {
            "question_id": "192",
            "sequenceId": 209,
            "question_text": "How about 4-12 months ago?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "noneId": "1200",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "used too much",
                    "id": "1189"
                },
                {
                    "text": "needed more to get the same effects",
                    "id": "1190"
                },
                {
                    "text": "used too often",
                    "id": "1191"
                },
                {
                    "text": "got in the way of home, family, job or school",
                    "id": "1192"
                },
                {
                    "text": "worried others",
                    "id": "1193"
                },
                {
                    "text": "took the place of other interests",
                    "id": "1194"
                },
                {
                    "text": "led to risky behavior",
                    "id": "1195"
                },
                {
                    "text": "kept using while feeling bad",
                    "id": "1196"
                },
                {
                    "text": "strong cravings",
                    "id": "1197"
                },
                {
                    "text": "hard to stop",
                    "id": "1198"
                },
                {
                    "text": "withdrawal symptoms if I stopped",
                    "id": "1199"
                },
                {
                    "text": "none of the above",
                    "id": "1200"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 209,
        "fields": {
            "question_id": "204",
            "sequenceId": 210,
            "question_text": "How often did you use others in the past month?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1201"
                },
                {
                    "text": "less than weekly",
                    "id": "1202"
                },
                {
                    "text": "weekly",
                    "id": "1203"
                },
                {
                    "text": "2-3x/week",
                    "id": "1204"
                },
                {
                    "text": "daily or most days",
                    "id": "1205"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 210,
        "fields": {
            "question_id": "216",
            "sequenceId": 211,
            "question_text": "Is this more or less than the past 6 months?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "about the same",
                    "id": "1314"
                },
                {
                    "text": "more often now",
                    "id": "1315"
                },
                {
                    "text": "less often now",
                    "id": "1316"
                },
                {
                    "text": "not sure",
                    "id": "1317"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 211,
        "fields": {
            "question_id": "228",
            "sequenceId": 212,
            "question_text": "How often 1-3 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1378"
                },
                {
                    "text": "less than weekly",
                    "id": "1379"
                },
                {
                    "text": "weekly",
                    "id": "1380"
                },
                {
                    "text": "2-3x/week",
                    "id": "1381"
                },
                {
                    "text": "daily or most days",
                    "id": "1382"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 212,
        "fields": {
            "question_id": "240",
            "sequenceId": 213,
            "question_text": "How often 4-6 months ago?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "never",
                    "id": "1443"
                },
                {
                    "text": "less than weekly",
                    "id": "1444"
                },
                {
                    "text": "weekly",
                    "id": "1445"
                },
                {
                    "text": "2-3x/week",
                    "id": "1446"
                },
                {
                    "text": "daily or most days",
                    "id": "1447"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 213,
        "fields": {
            "question_id": "117",
            "sequenceId": 214,
            "question_text": "Did any of these things happen because of your substance use (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "1483",
            "noneId": "1485",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "relationship issues",
                    "id": "1469"
                },
                {
                    "text": "injuries / accidents",
                    "id": "1470"
                },
                {
                    "text": "memory blackouts",
                    "id": "1471"
                },
                {
                    "text": "overdoses",
                    "id": "1472"
                },
                {
                    "text": "Narcan saves",
                    "id": "1473"
                },
                {
                    "text": "ER visits",
                    "id": "1474"
                },
                {
                    "text": "infections",
                    "id": "1475"
                },
                {
                    "text": "hospital stays",
                    "id": "1476"
                },
                {
                    "text": "detoxes",
                    "id": "1477"
                },
                {
                    "text": "arrested and held",
                    "id": "1478"
                },
                {
                    "text": "job losses",
                    "id": "1479"
                },
                {
                    "text": "homelessness",
                    "id": "1480"
                },
                {
                    "text": "suicidal",
                    "id": "1481"
                },
                {
                    "text": "deaths",
                    "id": "1482"
                },
                {
                    "text": "none",
                    "id": "1485"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 214,
        "fields": {
            "question_id": "118",
            "sequenceId": 215,
            "question_text": "Which of them happened more than once?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "1500",
            "otherOptionText": "other",
            "containsOther": true,
            "options": [
                {
                    "text": "relationship issues",
                    "id": "1486"
                },
                {
                    "text": "injuries / accidents",
                    "id": "1488"
                },
                {
                    "text": "memory blackouts",
                    "id": "1489"
                },
                {
                    "text": "overdoses",
                    "id": "1490"
                },
                {
                    "text": "Narcan saves",
                    "id": "1491"
                },
                {
                    "text": "ER visits",
                    "id": "1492"
                },
                {
                    "text": "infections",
                    "id": "1487"
                },
                {
                    "text": "hospital stays",
                    "id": "1493"
                },
                {
                    "text": "detoxes",
                    "id": "1494"
                },
                {
                    "text": "arrested and held",
                    "id": "1495"
                },
                {
                    "text": "job losses",
                    "id": "1496"
                },
                {
                    "text": "homelessness",
                    "id": "1497"
                },
                {
                    "text": "suicidal",
                    "id": "1498"
                },
                {
                    "text": "deaths",
                    "id": "1499"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 215,
        "fields": {
            "question_id": "119",
            "sequenceId": 216,
            "question_text": "Did getting arrested lead to any of the following (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "1505",
            "otherOptionText": "other",
            "containsOther": true,
            "options": [
                {
                    "text": "DUI(s)/ DWI(s)",
                    "id": "1502"
                },
                {
                    "text": "incarceration(s)",
                    "id": "1503"
                },
                {
                    "text": "probation(s)",
                    "id": "1504"
                },
                {
                    "text": "no",
                    "id": "1507"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 216,
        "fields": {
            "question_id": "120",
            "sequenceId": 217,
            "question_text": "When were you held in jail/detention (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "within the past 30 days",
                    "id": "1508"
                },
                {
                    "text": "1-3 months ago",
                    "id": "1509"
                },
                {
                    "text": "4-6 months ago",
                    "id": "1510"
                },
                {
                    "text": "6-12 months ago",
                    "id": "1511"
                },
                {
                    "text": "1-2 years ago",
                    "id": "1512"
                },
                {
                    "text": "3-5 years ago",
                    "id": "1513"
                },
                {
                    "text": "5+ years ago",
                    "id": "1514"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 217,
        "fields": {
            "question_id": "121",
            "sequenceId": 218,
            "question_text": "How long were you held?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "a few days or less",
                    "id": "1515"
                },
                {
                    "text": "a few weeks",
                    "id": "1516"
                },
                {
                    "text": "1-3 months",
                    "id": "1517"
                },
                {
                    "text": "4-6 months",
                    "id": "1518"
                },
                {
                    "text": "6-12 months",
                    "id": "1519"
                },
                {
                    "text": "1-2 years",
                    "id": "1520"
                },
                {
                    "text": "3-5 years",
                    "id": "1521"
                },
                {
                    "text": "5+ years",
                    "id": "1522"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 218,
        "fields": {
            "question_id": "122",
            "sequenceId": 219,
            "question_text": "How many times did you try to stop using?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "none",
                    "id": "1523"
                },
                {
                    "text": "once",
                    "id": "1524"
                },
                {
                    "text": "a few times",
                    "id": "1525"
                },
                {
                    "text": "many times",
                    "id": "1526"
                },
                {
                    "text": "don't remember",
                    "id": "1527"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 219,
        "fields": {
            "question_id": "123",
            "sequenceId": 220,
            "question_text": "Did you ever take medication to help you stop (methadone, suboxone, naltrexone, nicotine patch, etc.)?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "no",
                    "id": "1528"
                },
                {
                    "text": "yes",
                    "id": "1529"
                },
                {
                    "text": "yes, but don't know the name",
                    "id": "1530"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 220,
        "fields": {
            "question_id": "124",
            "sequenceId": 221,
            "question_text": "What medication?",
            "survey_id": "294219921",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "What medication?",
                    "id": "1531"
                },
                {
                    "text": "What medication?",
                    "id": "1532"
                },
                {
                    "text": "What medication?",
                    "id": "1533"
                },
                {
                    "text": "What medication?",
                    "id": "1534"
                },
                {
                    "text": "What medication?",
                    "id": "1535"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 221,
        "fields": {
            "question_id": "125",
            "sequenceId": 222,
            "question_text": "Was/is it helpful?",
            "survey_id": "294219921",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                [
                    {
                        "text": "no",
                        "id": "1536"
                    },
                    {
                        "text": "yes",
                        "id": "1537"
                    },
                    {
                        "text": "not sure",
                        "id": "1538"
                    }
                ],
                [
                    {
                        "type": "input",
                        "question": "What other medication?",
                        "id": 1
                    },
                    {
                        "text": "no",
                        "id": "1539"
                    },
                    {
                        "text": "yes",
                        "id": "1540"
                    },
                    {
                        "text": "not sure",
                        "id": "1541"
                    }
                ],
                [
                    {
                        "type": "input",
                        "question": "What other medication?",
                        "id": 2
                    },
                    {
                        "text": "no",
                        "id": "1542"
                    },
                    {
                        "text": "yes",
                        "id": "1543"
                    },
                    {
                        "text": "not sure",
                        "id": "1544"
                    }
                ],
                [
                    {
                        "type": "input",
                        "question": "What other medication?",
                        "id": 2
                    },
                    {
                        "text": "no",
                        "id": "1545"
                    },
                    {
                        "text": "yes",
                        "id": "1546"
                    },
                    {
                        "text": "not sure",
                        "id": "1547"
                    }
                ],
                [
                    {
                        "type": "input",
                        "question": "What other medication?",
                        "id": 2
                    },
                    {
                        "text": "no",
                        "id": "1548"
                    },
                    {
                        "text": "yes",
                        "id": "1549"
                    },
                    {
                        "text": "not sure",
                        "id": "1550"
                    }
                ]
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 222,
        "fields": {
            "question_id": "126",
            "sequenceId": 223,
            "question_text": "How much do you think your substance use affects / affected your life?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "not at all",
                    "id": "1551"
                },
                {
                    "text": "mildly affected",
                    "id": "1552"
                },
                {
                    "text": "moderately affected",
                    "id": "1553"
                },
                {
                    "text": "severely affected",
                    "id": "1554"
                },
                {
                    "text": "unsure",
                    "id": "1555"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 223,
        "fields": {
            "question_id": "127",
            "sequenceId": 224,
            "question_text": "What kind of help would you prefer for your opioid dependence?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "I prefer medication that avoids detox",
                    "id": "1556"
                },
                {
                    "text": "I'm willing to go through detox",
                    "id": "1557"
                },
                {
                    "text": "I'm unsure or want my provider to help me decide",
                    "id": "1558"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 224,
        "fields": {
            "question_id": "128",
            "sequenceId": 225,
            "question_text": "Since you want to avoid detox, which would you prefer:",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "replace with a long term opioid",
                    "id": "1559"
                },
                {
                    "text": "replace with a short term opioid",
                    "id": "1560"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 225,
        "fields": {
            "question_id": "129",
            "sequenceId": 226,
            "question_text": "Since you are willing to go through detox, which would you prefer:",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "detox, then non-opioid medication",
                    "id": "1561"
                },
                {
                    "text": "detox, then prefer no medication, even if harder",
                    "id": "1562"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 226,
        "fields": {
            "question_id": "144",
            "sequenceId": 227,
            "question_text": "Are there any other habits or behaviors you're concerned about (check all that apply)?",
            "survey_id": "294219921",
            "question_type": "multiple_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": "1641",
            "noneId": "1643",
            "otherOptionText": "other",
            "options": [
                {
                    "text": "OCD (obsessive compulsive)",
                    "id": "1631"
                },
                {
                    "text": "eating",
                    "id": "1632"
                },
                {
                    "text": "gambling",
                    "id": "1633"
                },
                {
                    "text": "over-exercising",
                    "id": "1634"
                },
                {
                    "text": "over-spending",
                    "id": "1635"
                },
                {
                    "text": "clutter or hoarding",
                    "id": "1636"
                },
                {
                    "text": "binge watching",
                    "id": "1637"
                },
                {
                    "text": "social media",
                    "id": "1638"
                },
                {
                    "text": "video games",
                    "id": "1639"
                },
                {
                    "text": "sex / porn",
                    "id": "1640"
                },
                {
                    "text": "None",
                    "id": "1643"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 227,
        "fields": {
            "question_id": "130",
            "sequenceId": 228,
            "question_text": "How concerned are you about your habit(s) or behavior?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                // {
                //     "text": "not at all",
                //     "id": "1563"
                // },
                {
                    "text": "mildly concerned",
                    "id": "1564"
                },
                {
                    "text": "moderately concerned",
                    "id": "1565"
                },
                {
                    "text": "very concerned",
                    "id": "1566"
                },
                {
                    "text": "unsure",
                    "id": "1567"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 228,
        "fields": {
            "question_id": "131",
            "sequenceId": 229,
            "question_text": "If you tried to stop or cut down on gambling, would you become restless, irritable, or anxious?",
            "bannerText": "During the past 12 months:",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "1568"
                },
                {
                    "text": "no",
                    "id": "1569"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 229,
        "fields": {
            "question_id": "132",
            "sequenceId": 230,
            "bannerText": "During the past 12 months:",
            "question_text": "Have you tried to keep your gambling a secret?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "1570"
                },
                {
                    "text": "no",
                    "id": "1571"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 230,
        "fields": {
            "question_id": "133",
            "sequenceId": 231,
            "question_text": "Have you needed help with living expenses because of your gambling?",
            "bannerText": "During the past 12 months:",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "1572"
                },
                {
                    "text": "no",
                    "id": "1573"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 231,
        "fields": {
            "question_id": "134",
            "sequenceId": 232,
            "question_text": "While doing this self-assessment, were you reminded of something worth improving in your life?",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "otherOPtionId": 1649,
            "otherOptionText": "say more, if you'd like",
            "containsOther": true,
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "yes",
                    "id": "1574"
                },
                {
                    "text": "no",
                    "id": "1575"
                },
                {
                    "text": "maybe",
                    "id": "1576"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 232,
        "fields": {
            "question_id": "135",
            "sequenceId": 233,
            "question_text": "I don't see a problem.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "bannerText": "Is there anything holding you back from working on whatever concerns you now have?",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1577"
                },
                {
                    "text": "disagree",
                    "id": "1578"
                },
                {
                    "text": "unsure",
                    "id": "1579"
                },
                {
                    "text": "agree",
                    "id": "1580"
                },
                {
                    "text": "strongly agree",
                    "id": "1581"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 233,
        "fields": {
            "question_id": "136",
            "sequenceId": 234,
            "question_text": "I'm not ready to work on it.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1582"
                },
                {
                    "text": "disagree",
                    "id": "1583"
                },
                {
                    "text": "unsure",
                    "id": "1584"
                },
                {
                    "text": "agree",
                    "id": "1585"
                },
                {
                    "text": "strongly agree",
                    "id": "1586"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 234,
        "fields": {
            "question_id": "137",
            "sequenceId": 235,
            "question_text": "I'm mostly feeling pressured by others.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1587"
                },
                {
                    "text": "disagree",
                    "id": "1588"
                },
                {
                    "text": "unsure",
                    "id": "1589"
                },
                {
                    "text": "agree",
                    "id": "1590"
                },
                {
                    "text": "strongly agree",
                    "id": "1591"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 235,
        "fields": {
            "question_id": "138",
            "sequenceId": 236,
            "question_text": "It's too hard.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1592"
                },
                {
                    "text": "disagree",
                    "id": "1593"
                },
                {
                    "text": "unsure",
                    "id": "1594"
                },
                {
                    "text": "agree",
                    "id": "1595"
                },
                {
                    "text": "strongly agree",
                    "id": "1596"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 236,
        "fields": {
            "question_id": "139",
            "sequenceId": 237,
            "question_text": "I don't know how to solve it.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1597"
                },
                {
                    "text": "disagree",
                    "id": "1598"
                },
                {
                    "text": "unsure",
                    "id": "1599"
                },
                {
                    "text": "agree",
                    "id": "1600"
                },
                {
                    "text": "strongly agree",
                    "id": "1601"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 237,
        "fields": {
            "question_id": "241",
            "sequenceId": 238,
            "question_text": "I feel alone.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1644"
                },
                {
                    "text": "disagree",
                    "id": "1645"
                },
                {
                    "text": "unsure",
                    "id": "1646"
                },
                {
                    "text": "agree",
                    "id": "1647"
                },
                {
                    "text": "strongly agree",
                    "id": "1648"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 238,
        "fields": {
            "question_id": "140",
            "sequenceId": 239,
            "question_text": "I've given up on me.",
            "survey_id": "294219921",
            "question_type": "single_choice",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "strongly disagree",
                    "id": "1602"
                },
                {
                    "text": "disagree",
                    "id": "1603"
                },
                {
                    "text": "unsure",
                    "id": "1604"
                },
                {
                    "text": "agree",
                    "id": "1605"
                },
                {
                    "text": "strongly agree",
                    "id": "1606"
                }
            ]
        }
    },
    {
        "model": "comprisapp.questions",
        "pk": 239,
        "fields": {
            "question_id": "completion",
            "question_text": "",
            "survey_id": "294219921",
            "created_at": "2023-12-06T10:06:52.696Z",
            "updated_at": "2023-12-06T10:06:52.696Z",
            "options": [
                {
                    "text": "Init",
                    "id": "1608"
                }
            ],
            "sequenceId": 240
        }
    }
]



export const medicationOptionsList = [
    [
        {
            text: 'medication:',
            id: '347',
        },
        {
            text: 'amount / dose:',
            id: '348',
        },
        {
            text: 'when taken:',
            id: '349',
        },
    ],
    [
        {
            text: 'medication:',
            id: '350',
        },
        {
            text: 'amount / dose:',
            id: '351',
        },
        {
            text: 'when taken:',
            id: '352',
        },
    ],
    [
        {
            text: 'medication:',
            id: '353',
        },
        {
            text: 'amount / dose:',
            id: '354',
        },
        {
            text: 'when taken:',
            id: '355',
        },
    ],
    [
        {
            text: 'medication:',
            id: '356',
        },
        {
            text: 'amount / dose:',
            id: '357',
        },
        {
            text: 'when taken:',
            id: '358',
        },
    ],
    [
        {
            text: 'medication:',
            id: '359',
        },
        {
            text: 'amount / dose:',
            id: '360',
        },
        {
            text: 'when taken:',
            id: '361',
        },
    ],
]

export const helpfulOptionsList = [
    [
        {
            "text": "no",
            "id": "1536"
        },
        {
            "text": "yes",
            "id": "1537"
        },
        {
            "text": "not sure",
            "id": "1538"
        },
    ],
    [
        {
            type: 'input',
            question: 'What other medication?',
            id: 1,
        },
        {
            "text": "no",
            "id": "1539"
        },
        {
            "text": "yes",
            "id": "1540"
        },
        {
            "text": "not sure",
            "id": "1541"
        },
    ],
    [
        {
            type: 'input',
            question: 'What other medication?',
            id: 2,
        },
        {
            "text": "no",
            "id": "1542"
        },
        {
            "text": "yes",
            "id": "1543"
        },
        {
            "text": "not sure",
            "id": "1544"
        },
    ],
    [
        {
            type: 'input',
            question: 'What other medication?',
            id: 3,
        },
        {
            "text": "no",
            "id": "1545"
        },
        {
            "text": "yes",
            "id": "1546"
        },
        {
            "text": "not sure",
            "id": "1547"
        },
    ],
    [
        {
            type: 'input',
            question: 'What other medication?',
            id: 4,
        },
        {
            "text": "no",
            "id": "1548"
        },
        {
            "text": "yes",
            "id": "1549"
        },
        {
            "text": "not sure",
            "id": "1550"
        }
    ]
]

export const whatMedicationsList = [
    {
        "text": "What medication?",
        "id": "1531"
    },
    {
        "text": "What medication?",
        "id": "1532"
    },
    {
        "text": "What medication?",
        "id": "1533"
    },
    {
        "text": "What medication?",
        "id": "1534"
    },
    {
        "text": "What medication?",
        "id": "1535"
    }
]
export const otherIdTextbox = [
    { "question_id": "3", "other_id": "24" },
    { "question_id": "4", "other_id": "36" },
    { "question_id": "5", "other_id": "54" },
    { "question_id": "10", "other_id": "71" },
    { "question_id": "11", "other_id": "82" },
    { "question_id": "13", "other_id": "107" },
    { "question_id": "26", "other_id": "163" },
    { "question_id": "55", "other_id": "331" },
    { "question_id": "68", "other_id": "413" },
    { "question_id": "89", "other_id": "559" },
    { "question_id": "91", "other_id": "609" },
    { "question_id": "117", "other_id": "1484" },
    { "question_id": "118", "other_id": "1501" },
    { "question_id": "119", "other_id": "1506" },
    { "question_id": "143", "other_id": "1629" },
    { "question_id": "144", "other_id": "1642" },
]


export const addSequence = (request) => {
      // Check if questionsAndChoices is empty
      if (!Array.isArray(questionsAndChoices) || questionsAndChoices.length === 0) {
        console.warn('questionsAndChoices array is empty or not provided');
        return request; // Return request as is if no questionsAndChoices provided
    }

    // Check if request or request.questions is empty
    if (!request || !Array.isArray(request.questions) || request.questions.length === 0) {
        console.warn('Request or request.questions array is empty or not provided');
        return request; // Return request as is if no questions provided
    }

    // Create a map from question_id to sequenceId for quick lookup
    const sequenceMap = questionsAndChoices.reduce((map, item) => {
        map[item.fields.question_id] = item.fields.sequenceId;
        return map;
    }, {});

    // Add sequenceId to each question in the request
    request.questions = request.questions.map(question => {
        if (!sequenceMap.hasOwnProperty(question.id)) {
            console.warn(`No sequenceId found for question id ${question.id}`);
        }
        return {
            ...question,
            sort_order:  sequenceMap[question.id] || null // Add sequenceId or null if not found
        };
    });

    return request;
}