import React, {useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Link,Switch } from "react-router-dom";

// Component
import Layout from './container/layout'
import Login from './components/onBoarding/login'
import Register from './components/register/register'
import ForgotPassword from './components/onBoarding/forgotPassword'
import ShortSurvey from './components/survey/survey'
import LongSurveyPublic from './components/survey/surveyLong'
import Survey from './components/survey2.0/survey'
import Newquestions from './components/survey2.0questions/Questions'
import LongSurvey from './components/surveyLong/survey'
import Questions from './components/Questions/Questions'
import LongSurveyQuestions from './components/QuestionsLong/Questions'
import service from './interceptor';
import axios from 'axios'

const App = () => {
  

  return (  
      <div>
            <ToastContainer />
            <Router>    
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/clinic-provider-signup" component={Register} />
                  <Route exact path="/assessment" component={Survey} />
                  <Route exact path="/assessmentShort" component={Survey} />

                  <Route exact path="/questions/:questionId" component={Newquestions} />
                  {/* <Route exact path="/assessment" component={LongSurvey} /> */}
                  {/* <Route exact path="/assessment" component={LongSurveyPublic} /> */}
                  <Route exact path="/forgotPassword" component={ForgotPassword} />
                  {/* <Route exact path="/questions/:questionId" component={Questions} />
                  <Route exact path="/surveyQuestions/:questionId" component={LongSurveyQuestions} /> */}
                  <Route path="/" component={Layout} />
                </Switch>
            </Router>
      </div>
  );
};
export default App;